import { LocalStorageService } from '../../../../_services/localstorage.service';
import { NewAccountInfo } from '../sign-up/sign-up.service';
import * as AuthActions from './auth.actions';

export interface PincodeValidation {
  validation: boolean;
  formValue: string;
}

export enum FormMode {
  logIn = 'logIn',
  signUp = 'signUp',
  recoveryPassword = 'recoveryPassword'
}

export interface State {
  isLoading: boolean;
  token: string;
  email: string;
  authenticated: boolean;
  expires: number;
  tokenUpdateTime: number;
  pincodeValidation: PincodeValidation;
  formIsActive: boolean;
  formMode: FormMode;
  autoCheckEmail: boolean;
  userDetailCheck: any;
  askForEmailSocial: boolean;
  

  signUpFormValue: Partial<NewAccountInfo>;
  signUpFormValid: boolean;

  oldCompanyId: number;
  newCompanyId: number;

  afterLoginRedirectTo: string;
}

const initialState: State = {
  isLoading: false,
  token: null,
  email: null,
  authenticated: false,
  expires: null,
  tokenUpdateTime: null,
  pincodeValidation: {
    validation: false,
    formValue: ''
  },
  formIsActive: false,
  formMode: null,
  autoCheckEmail: false,
  userDetailCheck: null,
  askForEmailSocial: false,
  
  signUpFormValue: {},
  signUpFormValid: false,
  oldCompanyId: null,
  newCompanyId: null,

  afterLoginRedirectTo: null
};

const localStorageService = new LocalStorageService();
const LOCAL_STATE_KEY = 'stateAuth';

export function authReducer(state = {
  ...localStorageService.getInitialState(LOCAL_STATE_KEY, initialState),
  formIsActive: initialState.formIsActive,
  formMode: initialState.formMode
}, action: AuthActions.AuthActions) {
  switch (action.type) {
    case (AuthActions.SET_PIN_INFO):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        pincodeValidation: {
          ...action.payload
        }
      });
    case (AuthActions.TRY_SIGNIN):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        isLoading: true
      });
    case (AuthActions.TRY_RECOVERY_PASS):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        isLoading: true
      });
    case (AuthActions.AUTH_SET_LOADING_STATE):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        isLoading: action.payload
      });
    case (AuthActions.SIGNIN):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        authenticated: true
      });
    case (AuthActions.LOGOUT):
      localStorageService.clear(LOCAL_STATE_KEY);
      localStorageService.clear('stateAuthOrig');
      localStorageService.clear('stateUserInfoOrig');
      return {
        ...state,
        token: null,
        expires: null,
        authenticated: false,
        tokenUpdateTime: null
      };
    case (AuthActions.REFRESH_TOKEN):
    case (AuthActions.SET_TOKEN):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        token: action.payload.token,
        tokenUpdateTime: action.payload.tokenUpdateTime
      });
    case (AuthActions.SET_EXPIRES):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        expires: action.payload
      });
    case (AuthActions.AUTH_UPDATE_FORM_STATE):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        formIsActive: action.payload
      });
    case (AuthActions.SELECT_FORM_MODE):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        formMode: action.mode
      });
    case (AuthActions.SET_DEFAULT_EMAIL):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        email: action.email
      });
    case (AuthActions.SET_AUTOCHECK_EMAIL):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        autoCheckEmail: action.autoCheck
      });
    case (AuthActions.SET_AUTOCHECK_USER_DETAIL):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        userDetailCheck: action.autoCheckDetail
      });
    case (AuthActions.ASK_FOR_EMAIL_AND_LOGIN_SOCIAL): 
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        askForEmailSocial: action.askForEmailSocial
      });
    case AuthActions.UPDATE_SIGNUP_DETAILS:
      return {
        ...state,
        signUpFormValue: {
          ...state.signUpFormValue,
          ...action.payload
        }        
      };
    case AuthActions.CLEAR_SIGNUP_DETAILS:
      return {
        ...state,
        signUpFormValue: null
      }
    case AuthActions.SIGNUP_FORM_VALID:
      return {
        ...state,
        signUpFormValid: action.payload
      }
    
    case AuthActions.PLATFORM_COMPANY_CHANGED:
      return {
        ...state,
        oldCompanyId: action.payload.oldId,
        newCompanyId: action.payload.newId
      }
    case AuthActions.AFTER_LOGIN_REDIRECT_TO:
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        afterLoginRedirectTo: action.payload
      });
    default:
      return state;
  }
};

export const getFormMode = (state: State) => state.formMode;
export const companyChanged = (state: State) => state.newCompanyId;
