import Quill from 'quill';

class Counter {
    quill: any;
    container: HTMLElement;

    constructor(quill: any, options: any) {
        this.quill = quill;
        if (options?.container) {
            this.container = document.getElementById(options.container);
        } else {
            this.container = document.createElement('div');
            this.container.style.position = 'absolute';
            this.container.style.bottom = '0px';
            this.container.style.right = '0px';
            this.container.style.padding = '5px';
            this.container.style.backgroundColor = 'rgba(0,0,0,0.1)';
            this.quill.container.appendChild(this.container);
        }        

        this.quill.on('text-change', this.update.bind(this, options));
        this.update(options);
    }

    update(options: any) {
        const text = this.quill.getText().trim();
        if (options?.maxLength) {
            let charLeft = text && text.length > 0 ? (options.maxLength - text.length) : options.maxLength;
            this.container.innerText = `${charLeft > 0 ? charLeft : 0} characters left`;
        } else {
            this.container.innerText = `${text && text.length > 0 ? text.length : 0} characters left`;
        }
    }
}

Quill.register('modules/counter', Counter);
