import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { QuestService } from 'src/app/_services/quest.service';
import { ShareWidjetService } from '../share-widjet/share-widjet.service';
import * as config from '../../../app.config';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/_store/app.reducers';

@Component({
  selector: 'app-share-widget-modal',
  templateUrl: './share-widget-modal.component.html',
  styleUrls: ['./share-widget-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareWidgetModalComponent implements OnInit {
  @Input() questId: number;

  @Input() feedPostId: number;

  url: string;

  connectionsForm: UntypedFormGroup;

  searchUserSubs: any = null;

  usersList = [];

  config = config;

  isUserAuthenticated: boolean = false;

  constructor(private modalService: NgbModal,
    private shareWidjetService: ShareWidjetService,
    private notifier: NotifierService,
    private fb: FormBuilder,
    private questService: QuestService,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>) {
    this.store.select('auth').subscribe(res => {
      this.isUserAuthenticated = res.authenticated;
    });
  }

  ngOnInit(): void {
    this.shareWidjetService.getShareUrl(this.questId).subscribe((res: string) => {
      this.url = res;

      if (this.feedPostId) {
        this.url = res + `?open=feed&postId=${this.feedPostId}`;
      }

      this.cdr.detectChanges();
    });

    this.connectionsForm = this.fb.group({
      searchUser: new UntypedFormControl(''),
      message: new UntypedFormControl(''),
      emails: new UntypedFormArray([ ])
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  shareViaFacebook() {
    this.shareWidjetService.shareViaFacebook(this.url);
  }

  copied() {
    this.notifier.notify('success', `The URL has been copied.`);
  }

  searchUsers(evnt) {
    const searchText = (evnt.target.value).trim();

    if (!this.isUserAuthenticated) {
      this.usersList = [{
        "id": null,
        "email": searchText,
        "name": searchText,
        "profilePictureURL": null,
        "coverPictureURL": null
      }];

      this.cdr.detectChanges();

      return;
    }

    if (this.searchUserSubs) this.searchUserSubs.unsubscribe();

    if (searchText == '') {
      return;
    }

    this.searchUserSubs = this.questService.searchUsers(searchText).subscribe(res => {
      let _res = res;
      if (_res && _res.length > 0) {
        if (this.connectionsForm.value.emails && this.connectionsForm.value.emails.length > 0) {
          this.connectionsForm.value.emails.forEach(userElem => {
            _res = res.filter(resUser => resUser.id !== userElem.userId );
          });
        }
      } else {
        // adding a temp user obj as per the user search
        _res.push({
          "id": null,
          "email": searchText,
          "name": searchText,
          "profilePictureURL": null,
          "coverPictureURL": null
        });
      }
      
      this.usersList = _res;

      this.cdr.detectChanges();
    }, () => {
      this.usersList = [];
    });
  }

  selectedUser(userItem) {
    if (userItem.id) {
      let list = this.connectionsForm.get('emails') as UntypedFormArray;
      list.push(new UntypedFormGroup({
        userEmail: new UntypedFormControl(userItem.email),
        userId: new UntypedFormControl(userItem.id || null),
        userName: new UntypedFormControl(userItem.name || null),
        userProfilePicture: new UntypedFormControl(userItem.profilePictureURL || null),
      }));
  
      this.connectionsForm.patchValue({
        searchUser: ''
      });
  
      this.usersList = [];
    } else {
      if (userItem.email.match(config.EMAIL_REGEX_VALIDATOR) && userItem.email.match(config.EMAIL_REGEX_VALIDATOR).length > 0) {
        let list = this.connectionsForm.get('emails') as UntypedFormArray;
        list.push(new UntypedFormGroup({
          userEmail: new UntypedFormControl(userItem.email),
          userId: new UntypedFormControl(null),
          userName: new UntypedFormControl(null),
          userProfilePicture: new UntypedFormControl(null)
        }));
    
        this.connectionsForm.patchValue({
          searchUser: ''
        });
    
        this.usersList = [];
      } else {
        this.notifier.notify('warning', 'Please, enter a valid email id')
      }
    }

    this.cdr.detectChanges();
  }

  removeSelectedUser(ind: number) {
    let list = this.connectionsForm.get('emails') as UntypedFormArray;
    list.removeAt(ind);

    this.cdr.detectChanges();
  }

  connectionFormSubmitted: boolean = false;
  submitConnections() {
    if (this.connectionFormSubmitted || !this.connectionsForm.value.emails || (this.connectionsForm.value.emails && this.connectionsForm.value.emails.length < 1)) {
      return;
    }

    this.connectionFormSubmitted = true;

    let validEmails: string[] = [];
    this.connectionsForm.value.emails.forEach(emailObj => {
      validEmails.push(emailObj.userEmail);
    });
    let _msg = this.connectionsForm.value.message;
    this.shareWidjetService.shareViaEmail(this.questId, validEmails, _msg).subscribe(() => {
      this.connectionFormSubmitted = false;

      this.notifier.notify('success', `Your Quest has been shared.`);
      this.closeModal();
    }, err => {
      this.connectionFormSubmitted = false;

      this.notifier.notify('error', 'There was an issue completing your request. Please try again.');
      this.closeModal();
    });
  }

  getInviteListControl() {
    return (this.connectionsForm.get('emails') as UntypedFormArray);
  }

  userTrackBy(id, user) {
    return user.id;
  }
}
