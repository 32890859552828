import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { ObservableInput, forkJoin, of, take } from 'rxjs';
import { NewTaskGroupAdded } from 'src/app/_interface/dl-milestones.actions';
import { TaskGroup } from 'src/app/_interface/dl-milestones.typings';
import { ManageQuestPayload } from 'src/app/_interface/quest-service.types';
import { QuestCategory, QuestTask } from 'src/app/_interface/quest.types';
import { QuestService } from 'src/app/_services/quest.service';
import { QUILL_CONFIG } from 'src/app/app.config';
import { VALIDATION_MESSAGES, ValidationMessages } from 'src/app/app.constants';
import * as fromApp from '../../../_store/app.reducers';
import { DlMilestonesService } from '../../main/dl-milestones/service/dl-milestones.service';
import * as fromProfile from '../../pages/profile/store/profile.reducer';
import { PaymentsService } from '../../pages/account/payments/payments.service';
import { DecimalPipe } from '@angular/common';
import { FundraiseSwitchModel } from '../../main/quest/manage/fundraise-switch/fundraise-switch.model';

@Component({
  selector: 'app-page-create',
  templateUrl: './page-create.component.html',
  styleUrls: ['./page-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DecimalPipe]
})
export class PageCreateComponent implements OnInit {
  questCreateForm: FormGroup;

  quest_description_length: number = 5000;

  @Input('creationOf') creationOf: string = 'quest';

  ngxConfig = {...QUILL_CONFIG};

  validationMessages: ValidationMessages = VALIDATION_MESSAGES;

  isLoading: boolean = false;

  userInfo: fromProfile.State;

  // @ViewChild('activityOptionsBox', { read: ElementRef }) activityOptionsBox: ElementRef;

  // showActivityOptions: boolean = false;

  // filteredActivities: any[] = [];

  selectedActivities: any[] = [];

  categoryList: QuestCategory[] = [];

  formIsSubmitted: boolean = false;

  hasValidStripeAccount: boolean = false;

  fundraisingConfig: FundraiseSwitchModel = {
      switchValue: false,
      currency: 'usd',
      targetAmount: null,
      enabled: false
    };

  constructor(private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private questService: QuestService,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private modalService: NgbModal,
    private milestoneService: DlMilestonesService,
    private paymentsService: PaymentsService,
    private cdr: ChangeDetectorRef,
    private decimalPipe: DecimalPipe) {
    this.createForm();
  }
  
  ngOnInit(): void {
    this.ngxConfig.counter = {container: 'counter', unit: 'character', maxLength: this.quest_description_length};

    this.getQuestCategories();

    this.store.select('userInfo').pipe(take(1)).subscribe((state: fromProfile.State) => {
      this.userInfo = state;

      this.cdr.detectChanges();
    });

    if (this.creationOf === 'page' || this.creationOf === 'fundraiser' || this.creationOf === 'donation') {
      this.questCreateForm.controls.questDescription.clearValidators();
      this.questCreateForm.controls.questDescription.setValidators([]);
      this.questCreateForm.controls.questDescription.setErrors(null);
    }

    if (this.creationOf === 'quest') {
      this.questCreateForm.controls.category.setValidators([Validators.required]);
      this.questCreateForm.controls.category.setErrors({required: true});
    }

    if (this.creationOf === 'donation' || this.creationOf === 'fundraiser') {
      this.getStripeDetails();
    }

    if (this.creationOf === 'fundraiser') {
      this.fundraisingConfig = {
        switchValue: true,
        currency: 'usd',
        targetAmount: 100 * 100,
        enabled: true
      };
      this.onFundraisingChange(this.fundraisingConfig);

      this.questCreateForm.get('fundraiseAmount').valueChanges.subscribe((value) => {
        this.formatAmount(value);
      });
    }
    
    this.cdr.detectChanges();
  }

  getQuestCategories() {
    // Note: this is hardcoded intentionally; we only want level 1 categories here for now.
    this.questService.getQuestCategoriesByLevel(1).subscribe((categories: QuestCategory[]) => {
      this.categoryList = [...categories];

      this.cdr.detectChanges();
    });
  }

  getStripeDetails() {
    this.paymentsService.testStripeAccount(this.userInfo.id).subscribe(() => {
      this.hasValidStripeAccount = true;
    }, () => {
      this.notifier.notify('error', 'Please Activate Payouts and add a Bank account before proceeding');

      this.hasValidStripeAccount = false;
    });
  }

  createForm() {
    this.questCreateForm = this.formBuilder.group({
      category: [null],
      questName: [null, [Validators.required, , Validators.maxLength(75), Validators.minLength(3)]],
      questDescription: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(this.quest_description_length)]],
      currency: ['usd'],
      fundraiseAmount: ['100', [Validators.required]],
      termsAccepted: [true, [Validators.required]],
      mode: [null, [Validators.required]]
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  createQuest() {
    this.formIsSubmitted = true;

    if (this.creationOf === 'event' || this.creationOf === 'fundraiser' || this.creationOf === 'donation') {
      this.questCreateForm.patchValue({
        mode: 'diyMode'
      });
    }

    if (this.creationOf === 'page') {
      this.questCreateForm.patchValue({
        mode: 'viewOnlyMode'
      })
    }

    this.selectedActivities = [{
      "id": 184,
      "activityName": "Sharing a post"
    }];

    if (this.creationOf === 'fundraiser' && !this.hasValidStripeAccount) {
      this.notifier.notify('error', 'Please Activate Payouts and add a Bank account before proceeding');

      return;
    }

    if (this.questCreateForm.invalid) {
      this.notifier.notify('error', 'Please complete required fields');

      return;
    }

    const _fundraiseAmount = this.getRawAmount(this.questCreateForm.value.fundraiseAmount);
    if (this.creationOf === 'fundraiser' && 
      (!_fundraiseAmount || 
      isNaN(_fundraiseAmount) 
      || Number(_fundraiseAmount) < 1)) {
      this.notifier.notify('error', 'Please enter fundraising target amount');

      return;
    }

    if (this.creationOf === 'fundraiser' && this.fundraisingConfig.switchValue && (!this.fundraisingConfig.enabled || !this.fundraisingConfig.targetAmount)) {
      this.notifier.notify('error', 'Please fill required fundraising details');

      return;
    }

    this.isLoading = true;

    this.questService.createQuest(this.buildCreateQuestPayload())
      .subscribe(res => {
        if (this.creationOf === 'page') {
          this.notifier.notify('success', 'Page draft saved');
          this.closeModal();
      
          // redirect to quest detail page
          this.router.navigate(['/quest-detail', res.id, this.userInfo.id, 0, 'details']);
          
          return;
        }

        let groupSources: [ObservableInput<TaskGroup>, ObservableInput<TaskGroup>] = [
          this.milestoneService.createNewTaskGroup(res.id, {
            groupName: 'System Activities',
            groupOwnerId: this.userInfo.id,
            groupOrder: 0,
            viewType: 'task',
            editableMilestones: false,
            milestoneControlsDisabled: true,
            tasksExpanded: false,
            logActivityCompulsory: false,
            taskViewDisabled: true,
            groupStatus: 'HIDDEN'
          }),


          this.creationOf === 'event' || this.creationOf === 'fundraiser' || this.creationOf === 'donation'
            ? of(null).pipe() 
            : this.milestoneService.createNewTaskGroup(res.id, {
              groupName: 'Tasks',
              groupOwnerId: this.userInfo.id,
              groupOrder: 1,
              viewType: 'task',
              editableMilestones: false,
              milestoneControlsDisabled: false,
              tasksExpanded: false,
              logActivityCompulsory: true,
              taskViewDisabled: false,
              groupStatus: 'NORMAL'
            })
        ];

        forkJoin(groupSources).subscribe(([hiddenTaskGroup, normalTaskGroup]) => {
          this.store.dispatch(new NewTaskGroupAdded(hiddenTaskGroup));
          this.creationOf === 'event' || this.creationOf === 'fundraiser' || this.creationOf === 'donation' ? null : this.store.dispatch(new NewTaskGroupAdded(normalTaskGroup));

          // creating task inside hidden group
          let _arrHidden = [];
          this.selectedActivities.forEach(act => _arrHidden.push(act.id));

          this.milestoneService.addMilestone(res.id, {
            title: 'Hidden Task',
            task: '',
            activities: this.selectedActivities,
            activitiesIds: _arrHidden,
            addedLink: '',
            imageUrl: null,
            videoUrl: null,
            video: null,
            embeddedVideo: null,
            linkUrl: null,
            linkPreview: null,
            linkedQuest: null,
            linkedQuestId: null,
            existingId: '',
            required: false,
            activityRecordListPoints: 1,
            groupIndex: 0,
            order: 0,
            questOwnerId: this.userInfo.id
          }).subscribe((hiddenTask) => {
              setTimeout(() => {
                this.closeModal();
              }, 50);
      
              if (this.creationOf === 'event') {
                this.notifier.notify('success', 'Event draft saved');
      
                // redirect to event creation page
                this.router.navigate(['/manage/event', res.id]);
      
                return;
              }

              if (this.creationOf === 'fundraiser') {
                this.notifier.notify('success', 'Fundraiser draft saved');
              }

              if (this.creationOf === 'donation') {
                this.notifier.notify('success', 'Donation form draft saved');
              }

              if (this.creationOf === 'page') {
                this.notifier.notify('success', 'Page draft saved');
              }

              if (this.creationOf === 'quest') {
                this.notifier.notify('success', 'Quest draft saved');
              }
      
              // redirect to quest detail page
              this.router.navigate(['/quest-detail', res.id, this.userInfo.id, 0, 'details']);
            }, taskErr => {

            });
          
        }, () => {
          this.notifier.notify('error', 'Issue processing request. Please try again later.');
        });
      }, err => {
        console.log(err);
      });

    this.cdr.detectChanges();
  }

  buildCreateQuestPayload(): ManageQuestPayload {
    let payload: ManageQuestPayload = {
      ...this.questCreateForm.value,
      showBackingAmount: (this.creationOf === 'donation' || this.creationOf === 'fundraiser') && this.hasValidStripeAccount,
      pillar: null,
      questTasks: null,
      admins: [],
      photo: '',
      privacy: 'public',
      copyAllowed: false,
      invites: [],
      backBtnDisabled: (this.creationOf === 'donation' || this.creationOf === 'fundraiser') ? (this.hasValidStripeAccount ? false : true) : true,
      tippingEnabled: this.creationOf === 'donation' && this.hasValidStripeAccount,
      monthlyDonationEnabled: false,
      fundraising: this.creationOf === 'fundraiser',
      fundraisingConfig: this.fundraisingConfig || null,
      multiTeamsAllowed: this.creationOf === 'fundraiser',
      accentColor: '',
      themeColor: '',
      revertCustomColor: false,
      questVideoUrl: null,
      tasksGroups: null,
      isTemplate: false,
      backBtnEnabled: (this.creationOf === 'donation' || this.creationOf === 'fundraiser') && this.hasValidStripeAccount,
      leaderboardEnabled: false,
      coverCenterX: null,
      coverCenterY: null,
      coverZoomValue: null,
      hideMembers: false,
      hideFeed: false,
      hideTeams: false,
      hidePeople: false,
      startBtnDisabled: false,
      isQuest: this.creationOf === 'quest',
      isEvent: this.creationOf === 'event',
      isContentOnly: this.creationOf === 'page',
      isFundraising: this.creationOf === 'fundraiser',
      isDonationsOnly: this.creationOf === 'donation'
    };

    return payload;
  }

  openedDropdown() {
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select-opened");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '99';
      };
    }, 100);
  }

  closedDropdown() {
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '9';
      };
    }, 200);
  }

  formatAmount(value: string | number) {
    if (!value) return;

    // Remove all non-numeric characters except decimal point
    const numericValue = value.toString().replace(/[^0-9.]/g, '');

    // Format number with commas
    const formattedValue = this.decimalPipe.transform(numericValue, '1.0-0', 'en-US');

    // Update the form control without triggering another change event
    this.questCreateForm.get('fundraiseAmount')?.setValue(formattedValue, { emitEvent: false });
  }

  getRawAmount(formValue): number {
    const formattedValue = formValue;
    return formattedValue ? parseFloat(formattedValue.replace(/,/g, '')) : 0;
  }

  onFundraisingChange(switchModel: FundraiseSwitchModel) {
    this.fundraisingConfig.enabled = switchModel.enabled;
    this.fundraisingConfig.targetAmount = switchModel.targetAmount;
    this.fundraisingConfig.switchValue = switchModel.switchValue;
  }
}
