import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Quest } from 'src/app/_interface/quest.types';
import { QuestService } from 'src/app/_services/quest.service';
import { HTTPStatus } from 'src/app/interceptors/loader.interceptor';
import { ReferenceDataService } from '../../../_services/reference-data.service';
import * as config from '../../../app.config';
import { API_URL } from '../../../app.config';
import * as Constants from '../../../app.constants';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactUsComponent implements OnInit {
  form: UntypedFormGroup;
  sent: boolean = false;
  questId?: number;
  userId?: number;
  // captchaToken: string = reCaptchaToken;
  messages = Constants.VALIDATION_MESSAGES;
  captchaFailed = false;
  showCaptcha = true;


  reCaptchaToken: string = config.reCaptchaToken;

  constructor(private http: HttpClient,
    private route: ActivatedRoute,
    private questService: QuestService,
    private referenceDataService: ReferenceDataService,
    private activeModal: NgbActiveModal,
    private httpStatus: HTTPStatus,
    private cdr: ChangeDetectorRef,
    private notifier: NotifierService
    ) { }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(config.EMAIL_REGEX_VALIDATOR)
      ])),
      message: new UntypedFormControl('', Validators.required),
      recaptcha: new UntypedFormControl('', Validators.required)
    });
  }

  onSubmit() {
    let contactForm = this.form.value;

    let text = '';
    for (let line of contactForm.message.split(/\r?\n/)) {
      text += line + '%0A';
    }
    this.route.children.forEach(child => {
      this.questId = parseInt(child.snapshot.paramMap.get('questId'));
      this.userId = parseInt(child.snapshot.paramMap.get('userId'));
    })
    if (this.questId && this.userId) {
      this.questService.getQuestDetail(this.questId, this.userId).subscribe((res: Quest) => {
        let questTitle = res && res.quest && res.quest.title ? res.quest.title : '';
        let header = questTitle ? 'Quest Support: ' + questTitle + '%0A': '';
        this.submit(contactForm.name, contactForm.email, header + text);
      });
    } else {
      this.submit(contactForm.name, contactForm.email, text);
    }
  }

  submit(name, email, msg) {
    console.log("Submitted Contact Us form")
    console.log(name, email, msg);
    
    const link = API_URL + `contact?name=${name}&email=${email}&desc=${msg}`;
    this.form.reset();
    this.reloadCaptcha();
    this.sent = true;
    this.httpStatus.setHttpStatus(true);
    return this.http.post(link, null)
      .subscribe((result) => {
        console.log(result);
        this.sent = true;

        this.notifier.notify('success', 'Message sent successfully');
        this.closeModal();

        this.cdr.detectChanges();
      });
  }
  
  reset() {
    this.form.reset();
    this.sent = false;
    this.captchaFailed = false;
    this.reloadCaptcha();
    this.cdr.detectChanges();
  }

  reloadCaptcha() {
    this.showCaptcha = false;
    setTimeout(() => {
      this.showCaptcha = true;
    });
    this.cdr.detectChanges();
  }

  checkBlankFields() {
    return this.form.get('name').hasError('required') ||
      this.form.get('email').hasError('required') ||
      this.form.get('message').hasError('required');
  }

  handleSuccess($event: string) {
    this.referenceDataService.verifyCapcha($event).subscribe((res: any) => {
      this.captchaFailed = !res || (res && !res.status) || (res && res.status && res.status !== 200);
      let disabledSignup = res && res.status && res.status === 200;
      this.form.patchValue({
        recaptcha: disabledSignup
      });

      this.cdr.detectChanges();
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  handleReset() {
    this.form.patchValue({
      recaptcha: false
    });

    this.cdr.detectChanges();
  }


  handleReady(captchaElem) {
    if (captchaElem) {
      captchaElem.execute();
    }
  }

  handleLoad() {
    // console.log('178 in load')
  }
}
