import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {ReaquestHeadersService} from 'src/app/_services/requestHeaders.service';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
// import { map } from 'rxjs/operators'
import * as fromApp from './../../../_store/app.reducers';
import * as UserFavoritesActions from './store/user-favorites.actions';
import * as RecentTransactionsActions from './store/recent-transactions.actions';
import { map, Observable } from 'rxjs';
import { LinkPayload, ProfileLink, UserPermission } from 'src/app/_interface/user-profile.types';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService,
    private store: Store<fromApp.AppState>
  ) {
  }

  // getUserFavorites() {
  //   this.store.dispatch(new UserFriendsActions.TryGetUserFriends());
  // }
  requestPayout(amountInCents: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      amount: amountInCents,
      currency: 'usd'
    };
    return this.http.post(
      environment.target + environment.context + '/accounts/bank-accounts/payout',
      payload,
      {headers: headers}
    );
  }

  getFirstBankAccountForPayouts() {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + '/accounts/bank-accounts/list/payouts',
      {headers: headers}
    );
  }

  getUserFavorites() {
    this.store.dispatch(new UserFavoritesActions.TryGetUserFavorites());
  }

  getRecentTransactions() {
    this.store.dispatch(new RecentTransactionsActions.TryGetRecentTransactions());
  }

  getUserInfoById(id: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/getuserinfobyid',
      {userId: id},
      {headers: headers}
    );
  }

  getUserInfoByName(username: string) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/getuserinfobyname',
      { username: username },
      { headers: headers }
    );
  }

  getUserInfo () {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + '/getuserinfo',
      {
        headers: headers,
        withCredentials: true
      }
    );
  }
  uploadProfilePictures(payload: { email: string, profPic?: any, coverPic?: any, contentType?: string, centerX?: number, centerY?: number, zoomValue?: number }) {
    const headers = this.reaquestHeadersService.getHeaders();
    const params = new HttpParams().set('format', 'base64');
    const data = new FormData();
    if (payload.profPic) {
      data.append('profPic', payload.profPic);
      data.append('profileZoomValue', (payload.zoomValue).toString());
      data.append('profileCenterX', (payload.centerX).toString());
      data.append('profileCenterY', (payload.centerY).toString());
    } else {
      data.append('coverPic', payload.coverPic);
      data.append('zoomValue', (payload.zoomValue).toString());
      data.append('centerX', (payload.centerX).toString());
      data.append('centerY', (payload.centerY).toString());
    }
    data.append('contentType', payload.contentType || '');
    data.append('email', payload.email);

    return this.http.post(
      environment.target + environment.context + '/uploadprofilepicture',
      data,
      {headers: headers, params: params}
    );
  }

  getUserFavoritesForUser(id: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/getuserfavoritesforuser',
      {userId: id},
      {headers: headers}
    );
  }

  getUserCurrentFriends(id: number): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/getcurrentfriendsforuser',
      {userId: id},
      {headers: headers}
    );
  }

  getSubscriptionTransactions(subscriptionId: string): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/accounts/subscriptions-transactions/${subscriptionId}`,
      {headers: headers}
    );
  }

  getSubscriptionTransactionsForSubscriber(subscriptionId: string, userId: number): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/accounts/subscriptions/${subscriptionId}/transactions/user/${userId}`,
      {headers: headers}
    );
  }

  getSubscriptions(pageNumber: number, pageSize: number): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/accounts/subscriptions/${pageNumber}/${pageSize}`,
      {headers: headers}
    );
  }

  getSubscribers(pageNumber: number, pageSize: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/accounts/subscribers/${pageNumber}/${pageSize}`,
      {headers: headers}
    );
  }

  cancelSubscription(subscriptionId: string): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/accounts/subscriptions/${subscriptionId}/cancel`,
      null,
      {headers: headers}
    );
  }

  getQuestsForUser(id: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quests/all-active/user/${id}`,
      {headers: headers}
    );
  }

  getCompletedQuestsForUser(id: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quests/all-completed/user/${id}`,
      {headers: headers}
    );
  }

  getAvailableBalance() {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/accounts/availablebalance`,
      {headers: headers}
    );
  }

  downloadTransactionsCSV(questId?: number) {
    const questIdQueryParam = questId ? `?questId=${questId}` : ""
    const headers = this.reaquestHeadersService.getHeaders();
    const options = {headers, responseType: 'blob' as 'text'};
    return this.http.get(
      environment.target + environment.context + `/accounts/transactions/download/csv${questIdQueryParam}`,
      options,
    );
  }

  downloadQuestTransactions(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    const options = {headers: headers, responseType: 'blob' as 'blob'};
    return this.http.get(environment.target + environment.context + `/quests/${questId}/participants`, options);
  }

  downloadActivityCSV(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    const options = {headers: headers, responseType: 'blob' as 'blob'};
    return this.http.get(environment.target + environment.context + `/activity/export/csv/${questId}`, options);
  }

  loadUserFavorites(userId) {
    return Observable.create((observer) => {
      this.getUserFavoritesForUser(userId)
      .subscribe((userFavorites: any) => {
        const interests = {
          favPhysical: false,
          favIntel: false,
          favSocial: false,
          favEnv: false,
          favOcc: false,
          favFin: false,
          favSpirit: true
        };
        userFavorites.forEach(interest => {
          switch (interest.favorite) {
            case 'PHYSICAL':
              interests.favPhysical = true;
              break;
            case 'MENTAL':
              interests.favIntel = true;
              break;
            case 'SOCIAL':
              interests.favSocial = true;
              break;
            case 'ENVIRONMENTAL':
              interests.favEnv = true;
              break;
            case 'OCCUPATIONAL':
              interests.favOcc = true;
              break;
            case 'FINANCIAL':
              interests.favFin = true;
              break;
          }
        });
        observer.next(interests);
      });
    });
  }

  getPillarData(userId) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/activity/pillars/${userId}`, 
      {headers: headers}
    );
  }

  getCompanyList(userId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/user/allcompanies/${userId}`, 
      {headers: headers}
    );
  }

  deleteAccount(userId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/deleteuser/${userId}`, 
      {headers: headers}
    );
  }

  deleteProfileLink(linkId: number): Observable<any> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<any>(
      environment.target + environment.context + `/profile/deleteprofile/${linkId}`, 
      {headers: headers}
    );
  }

  createLink(payload: LinkPayload): Observable<ProfileLink> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<ProfileLink>(
      environment.target + environment.context + `/profile/profilelink`, 
      payload,
      {headers: headers}
    );
  }

  getAllProfileLinks(userId: number): Observable<ProfileLink[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<ProfileLink[]>(
      environment.target + environment.context + `/profile/allprofilelinks/${userId}`, 
      {headers: headers}
    ).pipe(
      map((linkRes: ProfileLink[]) => {
        linkRes?.length > 0 ? linkRes.sort((linkA, linkB) => Number(linkA.displayOrder) - Number(linkB.displayOrder)) : null;

        return linkRes;
      })
    );
  }

  getUserPerimissions(userId: number): Observable<UserPermission> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<UserPermission>(
      environment.target + environment.context + `/profile/allpermissions/${userId}`, 
      {headers: headers}
    );    
  }

  updateProfileLinkOrder(payload: {id: number; order: number}[]): Observable<any> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<any>(
      environment.target + environment.context + `/profile/update-profilelink-order`, 
      {profileLinkList: payload},
      {headers: headers}
    );
  }
}
