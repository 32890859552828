import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from '../../main/contact-us/contact-us.component';
import { filter, startWith } from 'rxjs';

@Component({
  selector: 'app-common-footer',
  templateUrl: './common-footer.component.html',
  styleUrls: ['./common-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonFooterComponent implements OnInit {
  modalReference: any;
  
  year: number;

  constructor(private modalService: NgbModal,
    private router: Router,
    @Inject ('isServer') private isServer: boolean,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.year = new Date().getUTCFullYear()

    this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      startWith(this.router)).
      subscribe((val: NavigationEnd) => {
        if (val.url.toLowerCase().match('/contact') && val.url.toLowerCase().match('/contact').index > -1) {
          this.openContactForm();
        }
      });

    this.cdr.detectChanges();
  }

  openContactForm(): void {
    if (this.isServer) return;
    
    this.modalReference = this.modalService.open(ContactUsComponent, {
      windowClass: 'no-overflow'
    });
  }
}
