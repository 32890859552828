import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { QuestComment } from 'src/app/_interface/comment.types';
import { AddCommentToList, AnimateItems, AnimateList } from 'src/app/animations/comments.animations';
import { COMMENT_CHARACTER_LIMIT, QUILL_CONFIG } from 'src/app/app.config';
import { VALIDATION_MESSAGES, ValidationMessages } from 'src/app/app.constants';
import { WebviewNavigationDataType } from 'src/app/components/pages/quest-detail-comments/quest-detail-comments.component';
import { ProfileAvatarService } from 'src/app/components/separate/profile-avatar/profile-avatar.service';
import { environment } from 'src/environments/environment';
import tippy, { hideAll } from 'tippy.js';
import { QuestService } from '../../../../../_services/quest.service';
import * as fromApp from '../../../../../_store/app.reducers';
import * as fromProfile from '../../../../pages/profile/store/profile.reducer';
import * as CommentsActions from '../store/quest-components.actions';
import { CommentService } from './comment.service';
import { PreparedQuestComment } from './comment.type';
import { User } from 'src/app/_interface/user-profile.types';
import { FriendsService } from 'src/app/components/pages/friends/friends.service';
import { GetFriendsCurrent, GetFriendsNoAction, GetFriendsRequests } from 'src/app/components/pages/profile/store/profile.actions';
declare var $:any;

@Component({
  selector: 'app-comment',
  animations: [AnimateList, AnimateItems, AddCommentToList],
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentComponent implements OnInit, OnChanges {
  @Output() navigateToEmit: EventEmitter<WebviewNavigationDataType> = new EventEmitter<WebviewNavigationDataType>();
  @Output() closeAnotherReplyForms: EventEmitter<void> = new EventEmitter<void>();
  @Output() increaseRepliesLimit: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateRepliesCount: EventEmitter<void> = new EventEmitter<void>();

  @Input('comment') comment: PreparedQuestComment;
  
  @Input('reply') reply = false;
  
  @Input('postId') postId: number = null;
  
  @Input() questId: number;

  @Input('closeReplyForm') closeReplyForm: boolean;

  @Output() update: EventEmitter<{type: string; of?: number}> = new EventEmitter<{type: string; of?: number}>(); 

  validationMessages: ValidationMessages = VALIDATION_MESSAGES;
  profileState: Observable<fromProfile.State>;
  viwerId: number;
  form: UntypedFormGroup;
  replyForm: UntypedFormGroup;
  message = '';
  isLoading: string;
  isReplyFormOpened = false;
  isLikeLoading = false;
  hasCurrentUserLiked = false;
  tributeOptions: any;
  commentsViewLimit = 2;
  showRepliesList = false;
  addCommentToListState: string;

  public commentLength = COMMENT_CHARACTER_LIMIT;

  modalReference: any;

  showOptions: boolean = false;

  ngxConfig = {...QUILL_CONFIG};

  mentionsList: Array<any> = [];

  tributeVal: string = '';

  editingComment: boolean = false;

  commentFinal: any = '';

  @ViewChild('commentDivElem', {read: ElementRef, static: false}) commentDivElem: ElementRef;

  showReplyForm: boolean = false;

  commentReplies: PreparedQuestComment[] = [];

  pageNumber: number = 1;

  pageSize: number = 10;

  eventAlreadyAttached: boolean = false;

  focusCommentId: number;

  focusPostId: number;

  focusReplyId: number;
  
  get limit() {
    return this.reply ? this.comment.limit : this.comment.replies.length;
  }
  constructor(
    @Inject(DOCUMENT) private document: any,
    private elRef: ElementRef,
    private questService: QuestService,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private commentService: CommentService,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private notifier: NotifierService,
    private sanitizer: DomSanitizer,
    private profileAvatarService: ProfileAvatarService,
    private friendsService: FriendsService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    this.profileState = this.store.select('userInfo');
    this.tributeOptions = this.commentService.getTributeOptions();
  }

  ngOnInit() {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.focusCommentId = this.route.snapshot.queryParams['commentId'];
      this.focusPostId = this.route.snapshot.queryParams['postId'];
      this.focusReplyId = this.route.snapshot.queryParams['replyId'];
    });

    this.profileState.pipe(take(1)).subscribe((state: fromProfile.State) => {
      this.viwerId = state.id;
      // this.hasCurrentUserLiked = (this.comment.likes || []).filter(like => like.liker === this.viwerId).length > 0;
    });
    this.form = this.fb.group({
      editor: [this.comment.comment, [Validators.required]]
    });
    this.replyForm = this.fb.group({
      editor: ['', [Validators.required, Validators.maxLength(this.commentLength)]]
    });
    this.addCommentToListState = 'in';

    this.replyForm.controls.editor.valueChanges.subscribe(res => {
      this.formatCommentTributeVal(res);
    });

    this.form.controls.editor.valueChanges.subscribe(res => {
      this.formatCommentTributeVal(res);
    });

    this.prepareCommentCRLF(this.comment.comment);

    // console.log(152, this.focusCommentId, this.focusPostId, this.comment.actvityRecordValueId, this.comment.replies);
    if (this.focusCommentId && this.focusCommentId > 0 && this.focusPostId && this.focusReplyId && 
       Number(this.focusPostId) === this.comment.actvityRecordValueId &&
       Number(this.focusCommentId) === this.comment.commentId) {
      this.focusComment(true);
    }

    this.cdr.detectChanges();
  }

  ngOnChanges() {
    if (this.closeReplyForm !== null) {
      this.isReplyFormOpened = this.closeReplyForm;

      this.cdr.detectChanges();
    }
  }

  prepareCommentCRLF(comment: string) {
    let _comment: any = comment.replace(/\n/gm, '<br>');
    let newCommentArr: string[] = [_comment];
    if (this.comment.taggedUsers && this.comment.taggedUsers.length > 0) {
      newCommentArr = [];
      let commentArr = _comment.split('@');
      let taggedUsers = [...this.comment.taggedUsers];

      commentArr.forEach(_comnt => {
        if (_comnt !== '') {
          let isCommentFormatted = false;
          let _atComnt = '@' + _comnt;

          taggedUsers.forEach((_mention, i) => {
            let userFullName = `${_mention.firstName} ${_mention.lastName}`;
            // userFullName = userFullName.replaceAll(new RegExp(/[!@#$%^*()+=\-[\]\\';,./{}|":<>?~_]/, 'g'), '\\$&');
            let regexr = new RegExp(`@${userFullName}\\W`, 'g');
            // console.log('166', userFullName, regexr);
            let highElem = this.document.createElement('div');
            if (_atComnt.match(regexr)) {
              // split the name and rest sentence
              let _atComntSplit = _atComnt.split('@'+userFullName);
              _atComntSplit.forEach(splitStr => {
                if (splitStr === '') {
                  let highAtag = this.document.createElement('a');
                  highAtag.setAttribute('class', 'user-profile-link user-profile-link-box');
                  highAtag.setAttribute('data-index', i);
                  highAtag.href = `${environment.target}/profile/${_mention.userName}`;
                  let highSpantag = this.document.createElement('span');
                  highSpantag.innerHTML = `${_mention.firstName} ${_mention.lastName}`;
                  highAtag.appendChild(highSpantag);
                  highElem.append(highAtag);

                  taggedUsers.splice(i, 1);
                } else {
                  let highSpantag = this.document.createElement('span');
                  highSpantag.innerHTML = splitStr;
                  highElem.append(highSpantag);
                }
              });

              newCommentArr.push(highElem.innerHTML)
              isCommentFormatted = true;
            } 
          })

          if (!isCommentFormatted) {
            let highSpantag = this.document.createElement('span');
            highSpantag.innerHTML = _comnt;
            newCommentArr.push(highSpantag.innerHTML);
          }
        }
      });
    }

    this.commentFinal = this.sanitizer.bypassSecurityTrustHtml(newCommentArr.join(''));

    setTimeout(() => {
      if (this.commentDivElem && this.commentDivElem.nativeElement) {
        const allAtags = this.commentDivElem.nativeElement.querySelectorAll('.user-profile-link');
        allAtags.forEach(elem => {
          const index = Number(elem.getAttribute('data-index'));
          const instanceItem: any = tippy(elem.parentNode, {
            content: this.buildContent(this.comment.taggedUsers[index], this.comment.commentId),
            allowHTML: true,
            arrow: false,
            interactive: true,
            theme: 'light-border',
            onShow: event => {
              hideAll({ exclude: instanceItem });
              // const captoinEl = $(event.popper.querySelector('.tippy__caption'));
              // captoinEl.on('click.tippy', () => {
              //   this.router.navigate(['/profile', this.comment.mention[index].userId]);
              //   instanceItem.hide(0);
              // });
            },
            onHide: event => {
              const captoinEl = $(event.popper.querySelector('.tippy__caption'));
              captoinEl.off('click.tippy');
            }
          });
        });
      }

      this.cdr.detectChanges();
    }, 1000);

    this.cdr.detectChanges();
  }

  buildContent(info: User, commentId: number): string {
    let avatar;
    if (info.profilePictureURL) {
      // tslint:disable-next-line:max-line-length
      avatar = `<div class="c-avatar__inner" style="background-image: url('${info.profilePictureURL}')"></div>`;
    } else {
      const firstLetter = info.firstName.substr(0, 1);
      // tslint:disable-next-line:max-line-length
      avatar = `<div class="c-avatar__inner" style="background-color: ${this.profileAvatarService.getColor()};"><span>${firstLetter}</span></div>`;
    }
    let alreadyFriend = false;
    let commentOwnerLoggedIn = false;
    let alreadyRequestSent = false;
    let alreadyRequestReceived = false;
    this.profileState.pipe(take(1)).subscribe(userInfoState => {
      commentOwnerLoggedIn = info.id === userInfoState.id;
      alreadyFriend = userInfoState.currentFriends.findIndex(user => user.id === info.id) > -1;
      alreadyRequestSent = userInfoState.friendsNoAction.findIndex(user => user.id === info.id) > -1
      alreadyRequestReceived = userInfoState.friendRequests.findIndex(user => user.id === info.id) > -1
    });
    
    return this.buildHoverBox(avatar, info, commentOwnerLoggedIn, alreadyFriend, alreadyRequestSent, alreadyRequestReceived, commentId).innerHTML;
  }

  buildHoverBox(avatar, info, commentOwnerLoggedIn, alreadyFriend, alreadyRequestSent, alreadyRequestReceived, commentId: number) {
    let wrapperDiv = this.document.createElement('div');
    wrapperDiv.style.padding = '10px';
    wrapperDiv.setAttribute('class', 'tippy__mention');

    let btnDivWrap = this.document.createElement('div');
    if (!commentOwnerLoggedIn) {
      let btnDiv = this.document.createElement('div');
      btnDiv.setAttribute('class', 'tippy__btn-cont');

      if (!alreadyRequestReceived) {
        let btnElem = this.document.createElement('button');
        btnElem.setAttribute('type', 'button');
        btnElem.setAttribute('class', "e-btn e-btn_dark");
  
        if (!alreadyFriend && !alreadyRequestSent) {
          btnElem.innerHTML = 'Connect';
  
          if (!this.eventAlreadyAttached) {
            this.eventAlreadyAttached = true;
            this.document.addEventListener('click', (e) => {
              const target = e.target.getAttribute('id');
              if (target === 'connect-'+info.id+'-'+info.createdOn+'-'+commentId) {
                e.target.classList.add('is-loading');
                e.target.classList.add('is-disabled');
                e.target.setAttribute('disabled', 'true');

                this.friendsService.sendFriendRequest(info.id).subscribe((res) => {
                  e.target.classList.remove('is-loading');
                  e.target.innerHTML = 'Request Sent';
                  this.store.dispatch(new GetFriendsNoAction());
            
                  this.notifier.notify('success', 'Your connection request has been sent.');
                }, () => {
                  this.notifier.notify('error', 'There was an issue completing your request. Please try again.');
                });
              }
            });
          }
        }
        
        if (alreadyFriend) {
          btnElem.innerHTML = 'Connected';
          btnElem.setAttribute('disabled', 'true');
          btnElem.classList.add('is-disabled');
        }
  
        if (alreadyRequestSent) {
          btnElem.innerHTML = 'Request Sent';
          btnElem.setAttribute('disabled', 'true');
          btnElem.classList.add('is-disabled');
        }
        
        btnElem.setAttribute('id', 'connect-'+info.id+'-'+info.createdOn+'-'+commentId);
        btnDiv.appendChild(btnElem);
      }
      else if (alreadyRequestReceived) {
        btnDiv.classList.add('two-buttons');
        btnDiv.setAttribute('id', 'btn-container-'+info.id+'-'+info.createdOn+'-'+commentId)

        let acceptBtnElem = this.document.createElement('button');
        acceptBtnElem.setAttribute('type', 'button');
        acceptBtnElem.setAttribute('class', "e-btn e-btn_dark");
        acceptBtnElem.innerHTML = 'Accept';

        let rejectBtnElem = this.document.createElement('button');
        rejectBtnElem.setAttribute('type', 'button');
        rejectBtnElem.setAttribute('class', "e-btn e-btn_dark-revert");
        rejectBtnElem.innerHTML = 'Decline';

        acceptBtnElem.setAttribute('id', 'accept-connect-'+info.id+'-'+info.createdOn+'-'+commentId);
        rejectBtnElem.setAttribute('id', 'reject-connect-'+info.id+'-'+info.createdOn+'-'+commentId);
        btnDiv.appendChild(acceptBtnElem);
        btnDiv.appendChild(rejectBtnElem);

        if (!this.eventAlreadyAttached) {
          this.eventAlreadyAttached = true;
          this.document.addEventListener('click', (e) => {
            const target = e.target.getAttribute('id');
            if (target === 'accept-connect-'+info.id+'-'+info.createdOn+'-'+commentId) {
              e.target.classList.add('is-loading');
              e.target.classList.add('is-disabled');
              e.target.setAttribute('disabled', 'true');

              this.friendsService.acceptFriendRequest(info.id).subscribe((res) => {
                e.target.classList.remove('is-loading');
                e.target.innerHTML = 'Connected';
                
                let rejectElem = this.document.getElementById('reject-connect-'+info.id+'-'+info.createdOn+'-'+commentId);
                let btnDivElem = this.document.getElementById('btn-container-'+info.id+'-'+info.createdOn+'-'+commentId);
                if (rejectElem) {
                  rejectElem.remove();
                }

                if (btnDivElem) {
                  btnDivElem.classList.remove('two-buttons');
                }

                this.store.dispatch(new GetFriendsCurrent());
              }, () => {
                this.notifier.notify('error', 'There was an issue completing your request. Please try again.');
              });
            } else if (target === 'reject-connect-'+info.id+'-'+info.createdOn+'-'+commentId) {
              e.target.classList.add('is-loading');
              e.target.classList.add('is-disabled');
              e.target.setAttribute('disabled', 'true');

              this.friendsService.cancelFriendRequest(info.id).subscribe((res) => {
                let btnDiv = this.document.getElementById('btn-container-'+info.id+'-'+info.createdOn+'-'+commentId);
                if (btnDiv) {
                  btnDiv.remove();
                }

                this.store.dispatch(new GetFriendsRequests());
              }, () => {
                this.notifier.notify('error', 'There was an issue completing your request. Please try again.');
              });
            }
          });
        }
      } 

      // wrapperDiv.appendChild(btnDiv);
      btnDivWrap.append(btnDiv);
    }

    let nameDiv = `<div class="d-flex d-flex-row">
      <div class="tippy__avatar c-avatar">${avatar}</div>
      <div class="tippy__ttl">
        <a href="${environment.target}/profile/${info.id}">${info.firstName} ${info.lastName}</a>
        ${info.country && info.country !== null && info.country !== 'null' && info.country !== '' ? '<div class="location"><svg class="icon icon-location-pin"><use xlink:href="#icon-location-pin"></use></svg>'+info.country+'</div>' : ''}
        ${info.missionStatement && info.missionStatement !== '' ? '<div class="mission">'+info.missionStatement+'</div>' : ''}

        ${btnDivWrap.innerHTML}
      </div>
    </div>`;
    wrapperDiv.innerHTML = nameDiv;

    let mainWrapperDiv = this.document.createElement('div');
    mainWrapperDiv.appendChild(wrapperDiv);

    return mainWrapperDiv;
  }

  editComment() {
    this.store.select('quest').pipe(take(1)).subscribe(questState => {
      if (!questState.selectedQuestDetail.quest.isTemplate) {
        this.mentionsList = this.comment.taggedUsers || [];
        this.formatCommentTributeVal(this.comment.comment);
        this.editingComment = true;
      }
      this.showOptions = false;

      this.cdr.detectChanges();
    })
  }

  updateCommentReply() {
  if (!this.isLoading || this.form.valid) {
      this.isLoading = 'EDIT';
      let mentionStr = '';
      this.mentionsList.forEach((mention, i) => {
        const userId = mention.userId ? mention.userId : mention.id;
        mentionStr += (i == 0) ? userId : (',' + userId);
      });

      this.questService.editQuestCommentReply(this.comment.commentId, this.form.value.editor, mentionStr)
        .subscribe((newComment: PreparedQuestComment) => {
          this.isLoading = null;
          this.store.dispatch(new CommentsActions.UpdateComment(newComment));
          this.comment = newComment;
          this.toggleEditComment();

          this.notifier.notify('success', 'Your changes have been successfully saved.');

          this.cdr.detectChanges();
        });
    }
  }

  toggleEditComment() {
    this.editingComment = false;

    const payload: CommentsActions.ToggleEditCommentState = {
      commentId: this.comment.id,
      isEditable: !this.comment.editable
    };
    this.store.dispatch(new CommentsActions.ToggleEditComment(payload));
    
    this.prepareCommentCRLF(this.comment.comment);

    this.cdr.detectChanges();
  }

  toggleLikeComment() {
    this.isLikeLoading = true;

    this.cdr.detectChanges();
  }

  toggleReplyComment() {
    // this.showReplies();
    this.store.select('quest').pipe(take(1)).subscribe(questState => {
      if (!questState.selectedQuestDetail.quest.isTemplate) {
        this.showReplyForm = true;

        this.cdr.detectChanges();
      }
    })
  }

  cancelReply() {
    // this.isReplyFormOpened = false;
    this.replyForm.patchValue({
      editor: ''
    });
    this.form.patchValue({
      editor: ''
    });
    this.toggleEditComment();
  }
  
  onCloseAnotherReplyForms() {
    this.closeAnotherReplyForms.emit();
  }

  replyToComment() {
    if (!this.isLoading) {
      const replyId = this.comment.commentId ;// this.reply ? && this.postId ? this.postId : this.comment.id;
      this.isLoading = 'REPLY';
      let mentionStr = '';
      this.mentionsList.forEach((mention, i) => {
        mentionStr += (i == 0) ? mention.userId : (',' + mention.userId);
      });
      
      this.questService
        .addNewQuestComment(this.questId, this.postId, this.replyForm.value.editor, replyId, mentionStr)
        .subscribe((replyData: QuestComment) => {
          this.isLoading = null;
          this.replyForm.get('editor').patchValue('');

          this.comment.replies.push(replyData);

          this.cdr.detectChanges();
        }, () => {
          this.isLoading = null;
        });
    }
  }
  onNavigate(navigateData: WebviewNavigationDataType) {
    this.store.select('quest').pipe(take(1)).subscribe(questState => {
      if (!questState.selectedQuestDetail.quest.isTemplate) {
        if (navigateData.type === 'LinkInside') {
          this.router.navigate(navigateData.routerLink);
        } else {
          this.document.location.href = navigateData.url;
        }

        this.cdr.detectChanges();
      }
    });
  }

  emitRepliesCount() {
    this.updateRepliesCount.emit();
  }

  showReplies() {
    this.showRepliesList = true;

    this.cdr.detectChanges();
  }

  loadMoreReplies(firstTimeLoaded: boolean = false) {
    this.questService
      .getRepliesOnComment(this.comment.commentId, this.pageNumber, this.pageSize)
      .subscribe((replies: PreparedQuestComment[]) => {
        const existingReplies = this.comment.replies.map(repl => repl.commentId);
        replies = replies.filter(com => existingReplies.indexOf(com.commentId) < 0);
        replies = replies.reverse();

        this.pageNumber += 1;

        this.comment.replies.unshift(...replies);

        if (firstTimeLoaded) this.loadMoreReplies(true);

        this.cdr.detectChanges();
      })
  }

  confirmDeleteComment(template) {
    this.store.select('quest').pipe(take(1)).subscribe(questState => {
      if (!questState.selectedQuestDetail.quest.isTemplate) {
        this.modalReference = this.modalService.open(template, {
          size: 'sm',
          windowClass: 'confirm-modal'
        });
      }
    });
  }

  closeModal() {
    this.modalReference.dismiss();
    this.modalService.dismissAll();
  }

  deleteComment() {
    this.showOptions = false;
    this.questService
      .deleteComment(this.comment.commentId)
      .pipe(finalize(() => this.closeModal()))
      .subscribe((res: {"Success": boolean}) => {
        if (res && res.Success) {
          this.update.emit({
            type: 'removeComment',
            of: this.comment.commentId
          });
          this.notifier.notify('success', 'Comment deleted successfully.');

          this.cdr.detectChanges();
        } else {
          this.notifier.notify('error', 'Comment deletetion failed.');
        }
      }, err => {
        this.notifier.notify('error', 'Comment deletetion failed.');
      })
  }

  updateReply(event) {
    // console.log('585', event)
    if (event.type === 'removeComment') {
      const index = this.comment.replies.findIndex(item => item.commentId === event.of);
      this.comment.replies.splice(index, 1);
      this.comment.totalReplies -= 1;

      this.cdr.detectChanges();
    }
  }

  mentionItemSelected(ev) {
    if (this.mentionsList.length === 0 || this.mentionsList.findIndex(ment => ment.value === ev.value) < 0) {
      this.mentionsList.push(ev);

      this.cdr.detectChanges();
    }
  }

  commentKeyUp(ev, control) {
    if (ev.keyCode === 8 || ev.keyCode === 46) {
      const msg = control.value;
      if (msg.indexOf('@') < 0) {
        this.mentionsList = [];

        return;
      }

      if (this.mentionsList.length === 0) return;

      let allMentions = [...this.mentionsList];
      allMentions.forEach((mention, i) => {
        let regexr = new RegExp(`@${mention.key}\\W`)
        if (!msg.match(regexr) || (msg.match(regexr) && msg.match(regexr).length < 1)) {
          allMentions.splice(i, 1);
        }
      });

      this.mentionsList = [...allMentions];

      this.cdr.detectChanges();
    }
  }

  formatCommentTributeVal(comment: string) {
    let mentionText = this.commentService.escape(comment);

    this.mentionsList.forEach(mention => {
      const formattedMention = {
        ...mention,
        key: this.commentService.escape(mention.key ? mention.key : (mention.firstName + ' ' + mention.lastName))
      };
      let textSyntax = `@${formattedMention.key}`;
      let textHighlight = `<a href="javascript:void(0);"><span>@${formattedMention.key}</span></a>`;
      mentionText = mentionText.replace(new RegExp(textSyntax.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1"), 'g'), textHighlight);        
    });

    mentionText = mentionText.replace(/\n/g, '<br />'); //Replace the escape character for <br />
    mentionText = mentionText.replace(/ {2}/g, '&nbsp; '); //Replace the 2 preceding token to &nbsp;

    this.tributeVal = mentionText;

    this.cdr.detectChanges();
  }

  focusComment(firstTimeLoad: boolean = false) {
    if (firstTimeLoad && this.focusReplyId && this.focusReplyId > 0) {
      this.focusReplyId = Number(this.focusReplyId);
      let focussedReplyExists = false;
      // console.log('644', {...this.comment})
      this.comment.replies.forEach(act => {
        // console.log('647', act.commentId, this.focusReplyId)
        !focussedReplyExists ? (focussedReplyExists = act.commentId === this.focusReplyId) : null;
      });
      // console.log('649', focussedReplyExists);
      if (focussedReplyExists) {
        // scroll to the post
        setTimeout(() => {
          window.scrollTo(0, document.getElementById(`quest-post-comment-reply-${this.focusReplyId}`).offsetTop - 100);
          // document.getElementById(`quest-post-comment-reply-${this.focusReplyId}`).scrollIntoView({behavior:"smooth", block:"start"});
          document.getElementById(`quest-post-comment-reply-${this.focusReplyId}`).classList.add('highlight-focus-box');
          setTimeout(() => {
            document.getElementById(`quest-post-comment-reply-${this.focusReplyId}`).classList.remove('highlight-focus-box');
          }, 2000);
        }, 100);
      } else {
        if (this.comment.totalReplies > this.comment.replies.length) this.loadMoreReplies(true);
      }
    }
  }
}
