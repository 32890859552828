import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { QuestTask } from 'src/app/_interface/quest.types';
import { QuestService } from 'src/app/_services/quest.service';
import { QUILL_CONFIG } from 'src/app/app.config';
import { MILESTONE_TASK_MAX_LENGTH } from 'src/app/app.constants';
import { getYoutubeId } from 'src/app/directives/youtube-validation/youtube-validation.directive';
import { MILESTONE_TASK_DESC_MAX_LENGTH } from '../../../../../app.constants';
import { DlMilestonesService } from '../../service/dl-milestones.service';

@Component({
  selector: 'app-milestone-task',
  templateUrl: './milestone-task.component.html',
  styleUrls: ['./milestone-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MilestoneTaskComponent implements OnInit {
  @Input() taskForm: UntypedFormGroup;
  originalTaskFormData: object = {};
  @Output() taskUpdate: EventEmitter<any> = new EventEmitter;

  ngxConfig = {...QUILL_CONFIG};
  eventsSubject: Subject<void> = new Subject<void>();
  filteredActivities = [];
  selectedActivities: Array<any> = [];

  showActivityOptions: boolean = false;
  @ViewChild('activityOptionsBox', { read: ElementRef }) activityOptionsBox: ElementRef;
  activityPage: number = 0;
  runMoreApi: boolean = true;
  activityApi: any = null;
  activitySearchText: string = '';
  activityApiLoading = false;
  questEditType: string = null;

  @Input('isInModal') isInModal: boolean = false;
  
  @Input('viewType') viewType: string = 'task';

  existingImagesOfTask: Array<{imageURL: string, videoURL: string}> = [];

  uploadImageSubmit: boolean = false;
  
  doesImageExists: boolean = false;

  imageFile: File;

  MILESTONE_TASK_DESC_MAX_LENGTH = MILESTONE_TASK_DESC_MAX_LENGTH;

  currentDescriptionLength = 0;

  isAssetUploading: boolean = false;

  removeImages: boolean = false;

  isFormValuesChanged: boolean = false;

  @Input('submitForm') submitForm: boolean = false;
  
  @Input('isLogActivityCompulsory') isLogActivityCompulsory: boolean = true;

  formSubmitted: boolean = false;
  
  constructor(private dlMilestonesService: DlMilestonesService,
    private questService: QuestService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private notifier: NotifierService,
    private cdr: ChangeDetectorRef) { }

  @HostListener('document:click', ['$event'])
  outsideClick(event): void {
    if (this.activityOptionsBox && !this.activityOptionsBox.nativeElement.contains(event.target)) {
      this.showActivityOptions = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('81', {...changes}, this.submitForm);
    if (changes.submitForm && changes.submitForm.currentValue) {
      this.submitTask();

      setTimeout(() => {
        this.submitForm = false;
      }, 100);
    }

    if (!this.isInModal && changes.taskForm && changes.taskForm.currentValue) this.attachTaskFormDataAndEvent();
  }

  ngOnInit() {
    const paramsEditType = this.route.snapshot.paramMap.get('editType');
    this.questEditType = paramsEditType ? paramsEditType : null;

    if (this.isInModal) this.attachTaskFormDataAndEvent();
  }

  attachTaskFormDataAndEvent() {
    this.originalTaskFormData = this.taskForm && this.taskForm.value;

    // console.log('103 in attachTaskFormDataAndEvent');
    this.taskForm.controls.addedLink.valueChanges.subscribe(res => {
      // check if link is youtube
      if (!res || res === null || res === '' || (res && res.trim() === '')) {
        this.taskForm.patchValue({
          video: null,
          embeddedVideo: null,
          linkUrl: null,
          linkPreview: null
        });

        return;
      }

      const url = res;
      // const urlExt = url.toLowerCase().substring(url.length - 4, url.length);
      // else if (urlExt === '.mp4' || urlExt === '.mov') {
      //   // url is video url pasted directly. 
      //   // fetch the screenshot
        
      // } 
      if (url.indexOf('youtube') > -1 || url.indexOf('youtu.be') > -1) {
        this.getVideoForTask(url)
      } else {
        this.getLinkForTask(url);    
      }
    });

    if (this.taskForm.value && this.taskForm.value.activities && this.taskForm.value.activities.length > 0) {
      this.taskForm.value.activities.forEach(_activity => {
        this.selectedActivity(_activity);  
      });
    }

    this.taskForm.controls.searchActivity.valueChanges.subscribe(res => {
      this.activityPage = 0;
      this.filteredActivities = [];
      this.runMoreApi = true;
      this.activitySearchText = res;

      this.getActivities();
    });

    this.taskForm.valueChanges.subscribe(res => {
      if (!this.isFormValuesChanged) this.isFormValuesChanged = true;
    });

    this.existingImagesOfTask = !!this.taskForm.value.imageUrl ? [{
      imageURL: this.taskForm.value.imageUrl,
      videoURL: this.taskForm.value.videoUrl === 'null' ? null : this.taskForm.value.videoUrl
    }] : [];
  }

  showAndSearchOptions() {
    this.showActivityOptions = true;
    this.getActivities();
  }

  getActivities() {
    if (this.runMoreApi) {
      if (this.activityApi) {
        this.activityApi.unsubscribe();
      }

      this.activityApiLoading = true;
      this.activityApi = this.questService.getActivities(this.activityPage, null, this.activitySearchText).subscribe((res: Array<any>) => {
        if (res && (res as Array<any>).length > 0) {
          this.filteredActivities.push(...res);
          
          this.filterSelectedActivities();
        } else {
          this.runMoreApi = false;
        }
        this.activityApiLoading = false;
        this.cdr.detectChanges();
      }, err => {
        this.activityApiLoading = false;
      });
    }
  }

  onScrollActivity() {
    this.activityPage += 1;
    this.getActivities();
  }

  filterSelectedActivities() {
    this.selectedActivities.forEach(_act => {
      let itemIndex = this.filteredActivities.findIndex(fAct => fAct.id === _act.id);
      if (itemIndex > -1) {
        this.filteredActivities.splice(itemIndex, 1);
      }
    });
  }

  selectedActivity(activity) {
    const _act = activity;
    // console.log('155', _act);
    this.selectedActivities.push(_act);

    this.taskForm.patchValue({
      searchActivity: ''
    });
    this.showActivityOptions = false;
    this.activityPage = 0;
    // this.allActivities = [];
    this.filteredActivities = [];
    this.runMoreApi = true;
    this.activitySearchText = '';
    this.taskForm.markAsDirty();

    if (!this.isLogActivityCompulsory) {
      this.taskForm.controls.searchActivity.disable();
    }

    this.cdr.detectChanges();
  }

  removeActivity(activity) {
    const removeIndex = this.selectedActivities.findIndex(act => act.id === activity.id)
    this.selectedActivities.splice(removeIndex, 1);
    
    // add the removed activity back to filtered activity
    // const newActivitiyToAdd = this.allActivities.filter(act => act.id === activity.id);
    // this.filteredActivities.push(...newActivitiyToAdd);
    this.taskForm.markAsDirty();

    if (!this.isLogActivityCompulsory) {
      this.taskForm.controls.searchActivity.enable();
    }

    this.cdr.detectChanges();
  }

  isTaskInvalid(taskForm) {    
    return taskForm.invalid || this.selectedActivities.length < 1;
  }

  cancelTaskEdit() {
    this.taskForm.patchValue({
      title: this.originalTaskFormData['title'],
      task: this.originalTaskFormData['task'],
      addedLink: this.originalTaskFormData['addedLink'],
      imageUrl: this.originalTaskFormData['imageUrl'],
      video: this.originalTaskFormData['video'],
      embeddedVideo: this.originalTaskFormData['embeddedVideo'],
      linkUrl: this.originalTaskFormData['linkUrl'],
      linkPreview: this.originalTaskFormData['linkPreview'],
      linkedQuest: this.originalTaskFormData['linkedQuest'],
      linkedQuestId: this.originalTaskFormData['linkedQuestId'],
      videoUrl: this.originalTaskFormData['videoUrl'],
      edit: false
    });

    this.taskUpdate.emit({
      type: 'updated-task',
      milestoneForm: this.taskForm.value
    })
  }

  checkImageExist(event: boolean): void {
    this.doesImageExists = event;
    if (!this.doesImageExists) {
      this.taskForm.patchValue({
        imageUrl: null,
        videoUrl: null
      });
    }
  }

  imageUploaded(event) {
    this.taskForm.patchValue({
      imageUrl: event.imageUrl.imageURL,
      videoUrl: event.imageUrl.videoURL === 'null' ? null : event.imageUrl.videoURL
    });
    // console.log('event', event);

    this.uploadImageSubmit = false;

    this.saveTask();
  }

  submitTask() {
    if (this.taskForm.invalid) {
      this.notifier.notify('error', 'Please fill required fields');
      this.taskForm.markAllAsTouched();
      this.taskUpdate.emit({
        type: 'task-error',
        milestoneForm: this.taskForm.value
      });
      
      return;
    }

    if (this.viewType === 'task' && this.selectedActivities.length < 1) {
      this.notifier.notify('error', 'Please add at least one activity');
      this.taskForm.markAllAsTouched();
      this.taskUpdate.emit({
        type: 'task-error',
        milestoneForm: this.taskForm.value
      });
      
      return;
    }

    if (this.doesImageExists) {
      this.uploadImageSubmit = true;
    } else {
      this.taskForm.patchValue({
        imageUrl: this.taskForm.value.imageUrl || null,
        videoUrl: this.taskForm.value.videoUrl === 'null' ? null : this.taskForm.value.videoUrl
      });

      this.saveTask();
    }
  }

  saveTask() {
    // add activities to the task and emit
    let _arr = [];
    this.selectedActivities.forEach(act => {
      _arr.push(act.id);
    })
    this.taskForm.patchValue({
      activitiesIds: [..._arr],
      activities: this.selectedActivities,
      edit: false
    });

    if (this.formSubmitted) return;
    
    this.formSubmitted = true;
    this.taskUpdate.emit({
      type: 'updated-task',
      milestoneForm: this.taskForm.value
    });
  }

  getVideoForTask(videoUrl) {
    const youtubeId = getYoutubeId(videoUrl);
    if (youtubeId) {
      const videoData = this.questService.getVideoForTask(videoUrl);

      this.taskForm.patchValue({
        video: videoData.payload,
        embeddedVideo: videoData.embed
      });
    }
  }

  getLinkForTask(linkUrl) {
    // const payload: AddLinkPreviewToMilestonePayload = {
    const payload = {
      taskId: this.taskForm.value.existingId || null,
      taskLink: linkUrl
    };
    this.dlMilestonesService.addLinkToMilestone(payload).subscribe((newTask: QuestTask) => {
      this.taskForm.patchValue({
        linkUrl: newTask.linkUrl,
        linkPreview: newTask.linkPreview
      });

      this.cdr.detectChanges();
    });
  }

  get maxLengthCount(): number {
    return MILESTONE_TASK_MAX_LENGTH - (this.taskForm.value.title ? this.taskForm.value.title.length : 0);
  }

  closeModal() {
    if (this.isFormValuesChanged || this.doesImageExists) {
      if (window.confirm('Any changes made will be lost. Are you sure you want to close?')) {
        this.removeImages = true;
        this.doesImageExists = false;

        this.modalService.dismissAll();
      }

      return;
    }

    this.modalService.dismissAll();
  }

  /**
   * method: taskChanged
   * @param $event 
   * description: runs when content is changed in the task description
   */
  taskChanged($event): void {
    this.currentDescriptionLength = $event.editor.getLength();
  
    if ($event.editor.getLength() > MILESTONE_TASK_DESC_MAX_LENGTH) {
      $event.editor.deleteText(MILESTONE_TASK_DESC_MAX_LENGTH, $event.editor.getLength());
    }
  }

  /**
   * @method: assetUploading
   * @param event 
   * @description: get boolean value and set the asset upload status
   */
  assetUploading(event): void {
    this.isAssetUploading = event;
  }

  triggerClickImage: boolean = false;
  triggerClickEv() {
    this.triggerClickImage = true;

    setTimeout(() => {
      this.triggerClickImage = false;
    }, 100);
  }

  activityTrackBy(id, activity) {
    return activity.id;
  }
}
