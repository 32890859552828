import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';
import { AccountPlan } from 'src/app/_interface/account-tier.types';
import { ProfileLink, User, UserPermission } from 'src/app/_interface/user-profile.types';
import { environment } from '../../../../../environments/environment';
import { ReaquestHeadersService } from '../../../../_services/requestHeaders.service';
import * as fromApp from '../../../../_store/app.reducers';
import { GetAccountColors } from '../../account/store/account.actions';
import { SetTierVariants } from '../../diemlife-upgrade/tier-upgrade/store/tier-upgrade.actions';
import * as ProfileActions from './profile.actions';
import * as fromProfile from './profile.reducer';


@Injectable()
export class ProfileEffects {

  
  profileGetUserInfo = createEffect(() => this.actions$
    .pipe(
      ofType(ProfileActions.TRY_GET_USER_INFO),
      switchMap(() => {
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.get(
          environment.target + environment.context + '/getuserinfo',
          {
            headers: headers,
            withCredentials: true
          }
        );
      }),
      mergeMap((data: fromProfile.State) => {
        if (!data['brand']) {
          data['brand'] = null;
        } 

        this.store.dispatch(new GetAccountColors({userId: data.id}));
        this.store.dispatch(new ProfileActions.GetUserAccountTierInfo(data.id));
        this.store.dispatch(new ProfileActions.GetPlanTierDetails('DIEMlife'));

        return [
          {
            type: ProfileActions.GET_USER_PERMISSIONS,
            payload: data.id
          },
          {
            type: ProfileActions.GET_USER_MEMBERSHIP_MANAGER,
            payload: data.id
          },
          {
            type: ProfileActions.GET_USER_INFO,
            payload: data
          },
          {
            type: ProfileActions.GET_PROFILE_LINKS,
            payload: data.id
          }
        ];
      })
    ));

  profileGetMembershipManager = createEffect(() => this.actions$
    .pipe(
      ofType(ProfileActions.GET_USER_MEMBERSHIP_MANAGER),
      map((action: ProfileActions.GetUserMembershipManager) => {
        return action.payload;
      }),
      switchMap((userId: number) => {
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.get(
          environment.target + environment.context + `/managerdetail/byuserid/${userId}`,
          {
            headers: headers
          }
        );
      }),
      mergeMap((data: AccountPlan[]) => {
        this.store.dispatch(new ProfileActions.UpdateMembershipManagerDetails(data && data.length > 0, data));

        return [
        ];
      })
    ));

  profileGetPermissions = createEffect(() => this.actions$
    .pipe(
      ofType(ProfileActions.GET_USER_PERMISSIONS),
      map((action: ProfileActions.GetUserPermissions) => {
        return action.payload;
      }),
      switchMap((userId: number) => {
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.get(
          environment.target + environment.context + `/profile/allpermissions/${userId}`,
          {
            headers: headers
          }
        );
      }),
      mergeMap((data: UserPermission) => {
        this.store.dispatch(new ProfileActions.UpdateUserPermissions(data));
        
        return [];
      })
    ));

  profileGetLinks = createEffect(() => this.actions$
    .pipe(
      ofType(ProfileActions.GET_PROFILE_LINKS),
      map((action: ProfileActions.GetProfileLinks) => {
        return action.payload;
      }),
      switchMap((userId: number) => {
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.get(
          environment.target + environment.context + `/profile/allprofilelinks/${userId}`,
          {
            headers: headers
          }
        );
      }),
      mergeMap((data: ProfileLink[]) => {
        data?.length > 0 ? data.sort((linkA, linkB) => Number(linkA.displayOrder) - Number(linkB.displayOrder)) : null;

        this.store.dispatch(new ProfileActions.SetProfileLinks(data));
        
        return [];
      })
    ));

  getAccountTierInfo = createEffect(() => this.actions$
    .pipe(
      ofType(ProfileActions.GET_USER_ACCOUNT_TIER_INFO),
      map((action: ProfileActions.GetUserAccountTierInfo) => {
        return action.payload;
      }),
      switchMap((userId: number) => {
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.get(environment.target + environment.context + '/accounttier/planmembership/' + userId,
        {
          headers: headers
        }).pipe(
          map((res: AccountPlan) => {
            this.store.select('userInfo').pipe(take(1)).subscribe(userInfo => {
              if (userInfo && userInfo.id) {
                this.store.dispatch(new ProfileActions.UpdateAllowUseTemplatesPro(res.level === 'Ent' || res.level === 'Pro'));
                this.store.dispatch(new ProfileActions.UpdateAllowCreateTemplates(res.level === 'Ent'));
                this.store.dispatch(new ProfileActions.UpdateAllowUseTemplatesEnt(res.level === 'Ent'));

                this.store.dispatch(new ProfileActions.UpdateUserAccountTierInfo(res));

                this.store.select('userInfo').pipe(take(1)).subscribe(userInfo => {
                  if (userInfo && userInfo.id && ((environment.production && (userInfo.id === 67682 || userInfo.id === 73)) || (!environment.production && userInfo.id === 1386))) {
                    this.store.dispatch(new ProfileActions.UpdateAllowUseTemplatesPro(true));
                    this.store.dispatch(new ProfileActions.UpdateAllowCreateTemplates(true));
                    this.store.dispatch(new ProfileActions.UpdateAllowUseTemplatesEnt(true));

                    this.store.dispatch(new ProfileActions.UpdateUserAccountTierInfo({
                      "id": 2,
                      "level": "Ent",
                      "monthlyPricing": 55000,
                      "annualPricing": 600000,
                      "allowedMembers": 100,
                      "allowedQuests": 25,
                      "companyId": 1,
                      "planGroupId": 1,
                      "isPrivate": false
                    }));
                  }
                });
              }
            });
          })
        );
      })
    ), { dispatch: false });

  getPlanTiers = createEffect(() => this.actions$
    .pipe(
      ofType(ProfileActions.GET_PLAN_TIER_DETAILS),
      map((action: ProfileActions.GetPlanTierDetails) => {
        return action.payload;
      }),
      switchMap((getPlanOf: string) => {
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.get(environment.target + environment.context + `/plan/allPlans/${getPlanOf}`,
        {
          headers: headers
        }).pipe(
          map((res: AccountPlan[]) => {
            let _res = [...res];
            let blankPlans = [];

            // remove the null value plans
            _res.forEach((plan, i) => {
              if (!plan.annualPricing || plan.annualPricing < 1) {
                blankPlans.push(plan);
                _res.splice(i, 1);
              }
            });

            // sorting the plans
            _res.sort((planA, planB) => {
              if (planA.annualPricing < planB.annualPricing) {
                return -1;
              }
              return planA.annualPricing - planB.annualPricing
            });

            // adding blank plans at end
            _res.push(...blankPlans);
            
            this.store.dispatch(new SetTierVariants(getPlanOf, _res));
          })
        );
      })
    ), { dispatch: false })
  
  getCurrentFriends = createEffect(() => this.actions$
    .pipe(
      ofType(ProfileActions.GET_FRIENDS_CURRENT),
      switchMap(() => {
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.get(environment.target + environment.context + '/getcurrentfriends',
        {
          headers: headers
        })
      }),
      mergeMap((data: User[]) => {
        return [
          {
            type: ProfileActions.SET_FRIENDS_CURRENT,
            payload: data
          }
        ]  
      })
    ));
  
  getFriendRequests = createEffect(() => this.actions$
    .pipe(
      ofType(ProfileActions.GET_FRIENDS_REQUESTS),
      switchMap(() => {
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.get(environment.target + environment.context + '/getpendingfriendstoaccept',
        {
          headers: headers
        })
      }),
      mergeMap((data: User[] | any) => {
        if (data && data.error) {
          return [
            {
              type: ProfileActions.SET_FRIENDS_REQUESTS,
              payload: []
            }
          ]
        }

        return [
          {
            type: ProfileActions.SET_FRIENDS_REQUESTS,
            payload: data
          }
        ]  
      })
    ));

  getFriendNoActions = createEffect(() => this.actions$
    .pipe(
      ofType(ProfileActions.GET_FRIENDS_NO_ACTION),
      switchMap(() => {
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.get(environment.target + environment.context + '/getpendingfriendsnoaction',
        {
          headers: headers
        })
      }),
      mergeMap((data: User[] | any) => {
        if (data && data.error) {
          return [
            {
              type: ProfileActions.SET_FRIENDS_NO_ACTION,
              payload: []
            }
          ]
        }

        return [
          {
            type: ProfileActions.SET_FRIENDS_NO_ACTION,
            payload: data
          }
        ]  
      })
    ));

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService,
    private store: Store<fromApp.AppState>
  ) {}
}
