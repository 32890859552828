import { Action } from '@ngrx/store';
import { ProfileLink } from 'src/app/_interface/user-profile.types';

export const LOAD_NAVIGATION_LINKS = 'LOAD_NAVIGATION_LINKS';
export const LOAD_NAVIGATION_LINKS_SUCCESS = 'LOAD_NAVIGATION_LINKS_SUCCESS';

export class LoadNavigationLinks implements Action {
    readonly type = LOAD_NAVIGATION_LINKS;
    constructor(public payload: { contextType: 'quest' | 'profile' | 'other' }) {
        console.log('LoadNavigationLinks::', payload);
    }
}

export class LoadNavigationLinksSuccess implements Action {
    readonly type = LOAD_NAVIGATION_LINKS_SUCCESS;
    constructor(public payload: { links: ProfileLink[] }) {
        console.log('LoadNavigationLinksSuccess::', payload);
    }
}

export type NavigationActions = LoadNavigationLinks | LoadNavigationLinksSuccess;