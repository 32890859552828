import {
  AfterContentChecked,
  AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges
} from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { TicketPaymentInfo, TicketPaymentSubmitted } from 'src/app/modules/ticket-flow/pages/ticket-flow/store/ticket-flow.actions';
import { StripeErrorCodesEnum } from 'src/app/_interface/constants/stripe.error.codes.enum';
import { AppState } from 'src/app/_store/app.reducers';
import { EMAIL_REGEX_VALIDATOR, stripeToken } from '../../../app.config';
import { WindowRef } from '../../../_global/window-ref.module';
import * as fromAuth from '../../main/auth/store/auth.reducer';
import * as PaymentsActions from '../../pages/account/payments/store/payments.actions';
import * as fromPayments from '../../pages/account/payments/store/payments.reducer';
import { AccountService } from '../../pages/account/account.service';
import { SetLoadingStatus, UpdateBillingInfo, UpdateSignUpValue } from '../../pages/donate/store/donate.actions';
import * as fromProfile from '../../pages/profile/store/profile.reducer';
import { PaymentsService } from '../../pages/account/payments/payments.service';
import { BankAccount, CreditCard } from '../../pages/account/payments/store/payments.reducer';

export interface StructuredPayment {
  paymentMode: string;
  lastFour: string;
  token: string;
  save: boolean;
}

@Component({
  selector: 'app-pay-dialog',
  templateUrl: './pay-dialog.component.html',
  styleUrls: ['./pay-dialog.component.scss']
})
export class PayDialogComponent implements AfterViewInit, OnDestroy, OnChanges, AfterContentChecked {
  @Output() selectedPayment: any = new EventEmitter<StructuredPayment>();
  paymentsState: Observable<fromPayments.State>;
  authState: Observable<fromAuth.State>;
  profileState: Observable<fromProfile.State>;
  authStateSubscription: Subscription;
  stripe: any;
  elements: any;
  elemntsOptions = {
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:800'
      }
    ]
  };
  
  cardHandler = this.onChange.bind(this);

  cardValidation: { error: string | null, complete: boolean } = {
    error: null,
    complete: false
  };
  
  bankAccount: any = null;
  
  creditCardList: CreditCard[] = [];

  cardListToDisplay: CreditCard[] = [];
  
  saveNewCard = true;
  
  isAnonymous: boolean;

  newCardForm: UntypedFormGroup;

  paymentForm: UntypedFormGroup;

  cardNumberElem: any;

  cardCvvElem: any;
  
  cardExpiryElem: any;

  @Input('submitFormForPayment') submitFormForPayment: boolean; // if true then run the payment check post method

  @Output() paymentSubmitUpdate: EventEmitter<any> = new EventEmitter<any>(); //after payment check fail or success 

  cardFormValidation = {
    number: null,
    expiry: null,
    security: null
  };

  addNewCardObject = {
    cardType: 'new',
    expiryMonth: 0,
    expiryYear: 0,
    lastFourDigits: 'new'
  };

  @Input('askBillingInfo') askBillingInfo: boolean = false;
  
  @Input('billingData') billingData: {
    firstName: string;
    lastName: string;
    email: string;
    country: string;
    zip: number;
  } = {
    firstName: null,
    lastName: null,
    email: null,
    country: null,
    zip: null
  };

  billingDataPatched: boolean = false;

  signUpSubs: Subscription;

  constructor(
    private winRef: WindowRef,
    private paymentsService: PaymentsService,
    private accountService: AccountService,
    private store: Store<AppState>,
    private notifier: NotifierService,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private changeDetector: ChangeDetectorRef
  ) {
    this.paymentsState = this.store.select('paymentsInfo');
    this.profileState = this.store.select('userInfo');
    this.authState = this.store.select('auth');

    const Stripe = winRef.nativeWindow.Stripe;
    this.stripe = Stripe(stripeToken); // use your test publishable key
    this.elements = this.stripe.elements(this.elemntsOptions);

    this.paymentForm = this.fb.group({
      paymentType: ['creditCard'], //will have option as creditcard or bank account
      selectedPaymentType: [null], //will have card as creditcard or newcard string
      selectedPaymentIndex: [null],
      selectedPaymentDetail: [null]
    });

    this.newCardForm = this.fb.group({
      cardNumber: ['', Validators.required],
      cardExpiry: ['', Validators.required],
      cardCvv: ['', Validators.required]
    });

    // attaching event for the ticket registration
    this.store.select('ticketFlow').subscribe(ticketFlowRes => {
      if (ticketFlowRes.paymentSubmitted 
        && this.paymentForm.value.selectedPaymentType 
        && this.paymentForm.value.selectedPaymentType === 'newCardFilled') {
          this.onSubmitNewCreditCard();
          this.store.dispatch(new TicketPaymentSubmitted(false));
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.askBillingInfo) {
      if (this.askBillingInfo) {
        this.paymentForm = this.fb.group({
          paymentType: ['creditCard'], //will have option as creditcard or bank account
          selectedPaymentType: [null], //will have card as creditcard or newcard string
          selectedPaymentIndex: [null],
          selectedPaymentDetail: [null],
          billingInfo: this.fb.group({
            firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
            lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
            email: ['', [Validators.required, Validators.email, Validators.pattern(EMAIL_REGEX_VALIDATOR)]],
            country: ['US', [Validators.required]],
            zip: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/), Validators.minLength(1), Validators.maxLength(255)]]
          })
        });
      } else {
        this.paymentForm = this.fb.group({
          paymentType: ['creditCard'], //will have option as creditcard or bank account
          selectedPaymentType: [null], //will have card as creditcard or newcard string
          selectedPaymentIndex: [null],
          selectedPaymentDetail: [null]
        });
      }
    }

    // below code will work for donation
    console.log('154 paydialog', changes, this.submitFormForPayment, this.paymentForm.value);
    if (this.submitFormForPayment 
        && this.paymentForm.value.selectedPaymentType 
        && this.paymentForm.value.selectedPaymentType === 'newCardFilled') {
      this.onSubmitNewCreditCard();
      this.submitFormForPayment = false;
    }

    if (this.billingData && this.billingData.firstName && !this.billingDataPatched) {
      this.billingDataPatched = true;
      this.paymentForm.get('billingInfo').patchValue({
        firstName: this.billingData.firstName,
        lastName: this.billingData.lastName,
        email: this.billingData.email,
        country: this.billingData.country,
        zip: this.billingData.zip
      });
    }
  }

  ngAfterViewInit() {
    let oldAuthenticationState = false; //to save the original authentication state
    let isFirstTimeLoad = true;
    this.authStateSubscription = this.authState.subscribe((state: fromAuth.State) => {
      this.isAnonymous = !state.authenticated;
      
      if (oldAuthenticationState !== state.authenticated || isFirstTimeLoad) {
        oldAuthenticationState = state.authenticated;
        this.loadPaymentsInfo(isFirstTimeLoad ? false : true);
        isFirstTimeLoad = false;
      }

      if (state.authenticated) {
        this.profileState.pipe(take(1)).subscribe((fromProfileState: fromProfile.State) => {
          this.saveNewCard = !this.isAnonymous && Boolean(fromProfileState.stripeEntityId);
        });

        if (this.askBillingInfo) (this.paymentForm.get('billingInfo') as FormGroup).removeControl('signUp');

        if (this.signUpSubs) this.signUpSubs.unsubscribe();
      } else {
        this.saveNewCard = false;

        if (this.askBillingInfo) {
          (this.paymentForm.get('billingInfo') as FormGroup).addControl('signUp', new UntypedFormControl(false));

          this.signUpSubs = (this.paymentForm.get('billingInfo') as FormGroup).controls.signUp.valueChanges.subscribe(res => {
            this.store.dispatch(new UpdateSignUpValue(res));
          });
        }
      }
    });
  }

  ngOnDestroy() {
    this.removeCardAndEvents();

    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }
  }

  ngAfterContentChecked() : void {
    this.changeDetector.detectChanges();
  }

  loadPaymentsInfo(isCalledAfterLogin: boolean = false) {
    // tslint:disable-next-line:max-line-length
    this.cardListToDisplay = [this.addNewCardObject];
    if (this.isAnonymous) {
      this.autoFillChoice();

      return;
    }

    this.accountService.getFullyPaymentsInfo()
      .subscribe((
        [[bankInfoPayments, bankInfoPayouts], creditCardsInfo]: [BankAccount[][], CreditCard[]]
      ): void => {
        this.bankAccount = bankInfoPayouts;
        this.creditCardList = creditCardsInfo;
        this.cardListToDisplay = [...creditCardsInfo, this.addNewCardObject];
        this.changeDetector.detectChanges();

        this.store.dispatch(new PaymentsActions.SetPaymentsInfo({
          bankAccounts: this.bankAccount,
          creditCards: this.creditCardList,
          hasBankAccountForPayments: !!bankInfoPayments.length,
          hasBankAccountForPayouts: !!bankInfoPayouts.length
        }));
        
        this.autoFillChoice(isCalledAfterLogin);
    }, () => {
      // * if nothing to auto select
    });
  }

  autoFillChoice(isCalledAfterLogin: boolean = false) {
    console.log('215', isCalledAfterLogin);
    if (this.creditCardList.length > 0) {
      this.selectPayment('creditCard', this.creditCardList.length - 1);
    } else if(this.cardListToDisplay.length === 1) {
      // this should not emit data, if called after login.
      // as it creates issue while purchasing plan as it sends the payment data as null again
      if (!isCalledAfterLogin) this.selectPayment('creditCard', this.cardListToDisplay.length - 1);
    } else {
      this.paymentsState.pipe(take(1)).subscribe((state: fromPayments.State) => {
        if (state.hasBankAccountForPayments) {
          this.selectPayment('bankAccount', 0);
        }
      });
    }
  }
  /*
  removeCreditCard(index: number) {
    this.paymentsState.pipe(take(1)).subscribe((paymentsState: fromPayments.State) => {
      const lastFourDigits: string = paymentsState.creditCards[index].lastFourDigits;

      this.paymentsService.removeCreditCard(lastFourDigits).subscribe(res => {
        this.store.dispatch(new PaymentsActions.RemoveCreditCard(index));
        this.creditCardList.splice(index, 1);
        this.cardListToDisplay = [...this.creditCardList, this.addNewCardObject];
      });
    });
  }

  removeBankAccount(index: number) {
    this.paymentsService.removeBankAccount().subscribe(res => {
      this.store.dispatch(new PaymentsActions.RemoveBankAccount(index));
    });
  }
  */

  onChange({error, complete, elementType}) {
    this.cardValidation.complete = complete;
    if (error) {
      this.cardValidation.error = error.message;
    } else {
      this.cardValidation.error = null;
    }

    if (elementType === 'cardNumber') {
      this.cardFormValidation.number = {
        error: error,
        complete: complete
      }
    } else if (elementType === 'cardExpiry') {
      this.cardFormValidation.expiry = {
        error: error,
        complete: complete
      }
    } else if (elementType === 'cardCvc') {
      this.cardFormValidation.security = {
        error: error,
        complete: complete
      }
    }
    
    this.paymentForm.patchValue({
      selectedPaymentType: 'newCard'
    });
    if (this.cardFormValidation.number && this.cardFormValidation.number.complete
        && this.cardFormValidation.expiry && this.cardFormValidation.expiry.complete
        && this.cardFormValidation.security && this.cardFormValidation.security.complete
    ) {
      this.paymentForm.patchValue({
        selectedPaymentType: 'newCardFilled' //changing type from new to newField
      });

      console.log('pay-dialog component 273');
      this.onSubmit();
    }
  }

  selectPayment(payment: string, index: number) {
    // this.selectedPaymentOption.detail = (payment === 'bankAccount') ? (this.bankAccount[index]) : (this.cardListToDisplay[index]);
    this.paymentForm.patchValue({
      selectedPaymentType: payment,
      selectedPaymentIndex: index,
      selectedPaymentDetail: (payment === 'bankAccount') ? (this.bankAccount[index]) : (this.cardListToDisplay[index])
    });
    
    const paymentDetail = this.cardListToDisplay[index];
    console.log('296 pay dialog', paymentDetail, payment);
    if (paymentDetail['lastFourDigits'] === 'new' && payment !== 'bankAccount') {
      this.paymentForm.patchValue({
        selectedPaymentType: 'newCard',
        selectedPaymentIndex: index,
        selectedPaymentDetail: paymentDetail
      });

      setTimeout(() => {
        if (!this.cardNumberElem) {
          this.drawCardAndAddEvents();
        } else {
          this.mountElementsToCard();
        }
      }, 10);
    }
    
    console.log('pay-dialog component 303');
    this.onSubmit();
  }

  onSubmit() {
    switch (this.paymentForm.value.selectedPaymentType) {
      case 'creditCard':
        this.onSubmitCreditCard();
        break;
      case 'newCard':
      case 'newCardFilled':
        this.onSubmitCreditCard();
        break;
      case 'bankAccount':
        this.onSubmitBankAccount();
        break;
      default:
        this.notifier.notify('warning', 'Please choose a payment method.');
    }
  }

  async onSubmitNewCreditCard() {
    this.store.dispatch(new SetLoadingStatus(true));
    const {token, error} = await this.stripe.createToken(this.cardNumberElem);

    if (error) {
      this.cardValidation.error = error.message;
      this.notifier.notify('error', this.cardValidation.error);
      this.store.dispatch(new SetLoadingStatus(false));

      return;
    }

    /* calling right now only for donation because change was done only in donation at this time */
    if (this.askBillingInfo) {
      console.log('422 pay dialog', this.paymentForm.value);
      this.store.dispatch(new UpdateBillingInfo(this.paymentForm.value.billingInfo));
    }

    console.log('386 paydialog', this.paymentForm.get('billingInfo'));
    if (this.askBillingInfo && this.paymentForm.get('billingInfo').invalid) {
      // this.submitFormForPayment = true;
      this.notifier.notify('error', 'Please fill required fields');
      this.store.dispatch(new SetLoadingStatus(false));

      return;
    }

    const newCardInfo: fromPayments.CreditCard = {
      cardType: token.card.brand,
      expiryMonth: token.card.exp_month,
      expiryYear: token.card.exp_year,
      lastFourDigits: token.card.last4,
      save: this.saveNewCard,
      token: token.id
    };

    if (!this.saveNewCard) {
      this.paymentForm.patchValue({
        selectedPaymentType: 'newCreditCard',
        selectedPaymentDetail: newCardInfo
      });
      const struncturedPayment: StructuredPayment = this.getStructuredEmittedPaymentInfo();
      console.log('pay-dialog component 349', struncturedPayment);
      this.selectedPayment.emit(struncturedPayment);
      
      this.paymentSubmitUpdate.emit({
        paymentTypeConfirm: true,
        paymentDetail: this.paymentForm.value.selectedPaymentDetail
      });
      // this.store.dispatch(new SetLoadingStatus(false));
      this.store.dispatch(new TicketPaymentInfo({
        selectedPaymentDetail: struncturedPayment,
        paymentDetail: this.paymentForm.value.selectedPaymentDetail,
        paymentTypeConfirm: true
      }));
      
      return;
    }

    let alreadyExists = false;
    this.creditCardList.forEach((card: fromPayments.CreditCard) => {
      if (card.lastFourDigits === token.card.last4) {
        alreadyExists = true;
        return false;
      }
    });

    if (alreadyExists) {
      this.notifier.notify('error', 'This card already exists');
      this.store.dispatch(new SetLoadingStatus(false));

      return;
    }

    this.paymentsService.saveCreditCard(token.id).subscribe((res) => {
      this.store.dispatch(new PaymentsActions.AddCreditCard(newCardInfo));
      
      this.paymentForm.patchValue({
        selectedPaymentType: 'newCreditCard',
        selectedPaymentDetail: newCardInfo
      });
      
      this.creditCardList.push(newCardInfo);
      this.cardListToDisplay = [...this.creditCardList, this.addNewCardObject]

      setTimeout(() => {
        this.selectPayment('creditCard', this.creditCardList.length - 1);

        this.paymentSubmitUpdate.emit({
          paymentTypeConfirm: true,
          paymentDetail: this.paymentForm.value.selectedPaymentDetail
        });

        const struncturedPayment: StructuredPayment = this.getStructuredEmittedPaymentInfo();
        console.log('pay-dialog component 402');
        this.selectedPayment.emit(struncturedPayment);
        
        this.store.dispatch(new TicketPaymentInfo({
          paymentTypeConfirm: true,
          paymentDetail: struncturedPayment,
          selectedPaymentDetail: struncturedPayment
        }));
      });
    }, (err) => {
      let isError = false;
      let errorValue = '';
      for (const errorCode in StripeErrorCodesEnum) {
        if (err.error && err.error.description.search(errorCode) !== -1) {
          isError = true;
          errorValue = StripeErrorCodesEnum[errorCode];
        } 
        else if (err.description && err.description.search(errorCode) !== -1) {
          isError = true;
          errorValue = StripeErrorCodesEnum[errorCode];
        }
      }
      if (isError) {
        this.notifier.notify('error', errorValue);
      } else {
        this.notifier.notify('error', 'We cannot process your payment at this time. Please try again.');
      }

      this.store.dispatch(new SetLoadingStatus(false));
    });
  }

  getStructuredEmittedPaymentInfo(): StructuredPayment {
    let payload: StructuredPayment = {
      paymentMode: '',
      lastFour: '',
      token: '',
      save: true
    };
    
    switch (this.paymentForm.value.selectedPaymentType) {
      case 'newCreditCard':
        /**
         * when the card is filled and saved. ready to pay.
         */
        payload = {
          paymentMode: 'creditCardToken',
          lastFour: this.paymentForm.value.selectedPaymentDetail['lastFourDigits'],
          token: this.paymentForm.value.selectedPaymentDetail['token'],
          save: this.paymentForm.value.selectedPaymentDetail['save']
        };
        break;
      case 'newCard':
      case 'newCardFilled':
        const cardDetail = this.cardListToDisplay[this.paymentForm.value.selectedPaymentIndex];
        payload = {
          /**
           * if cardType = new, then new card is slected but not filled
           * if cardType = newCreditCard, i.e. it is filled now and we need to pass new elements
           */
          paymentMode: this.paymentForm.value.selectedPaymentType === 'newCardFilled' ? this.paymentForm.value.selectedPaymentType : '',
          lastFour: this.paymentForm.value.selectedPaymentType === 'newCardFilled' ? cardDetail['lastFourDigits'] : '',
          token: null,
          save: null
        };
        break;
      case 'creditCard':
        let _paymentDetail = this.cardListToDisplay[this.paymentForm.value.selectedPaymentIndex];
        payload = {
          /**
           * if cardType = new, then new card is slected but not filled
           * if cardType = newCreditCard, i.e. it is filled now and we need to pass new elements
           */
          paymentMode: 'creditCard',
          lastFour: _paymentDetail['lastFourDigits'],
          token: _paymentDetail['token'] ? _paymentDetail['token'] : null,
          save: _paymentDetail['save'] ? _paymentDetail['save'] : null
        };
        break;
      case 'bankAccount':
        let bankDetail = this.bankAccount[this.paymentForm.value.selectedPaymentIndex];
        payload = {
          paymentMode: 'bankAccount',
          lastFour: bankDetail['lastFourDigits'] || null,
          token: null,
          save: null
        };
        break;
    }
    return payload;
  }

  onSubmitCreditCard() {
    const struncturedPayment: StructuredPayment = this.getStructuredEmittedPaymentInfo();
    console.log('pay-dialog component 496');
    this.selectedPayment.emit(struncturedPayment);
    this.store.dispatch(new TicketPaymentInfo({
      selectedPaymentDetail: struncturedPayment,
      paymentDetail: null,
      paymentTypeConfirm: false
    }));

    /* calling right now only for donation because change was done only in donation at this time */
    if (this.askBillingInfo) {
      console.log('575 pay dialog', this.paymentForm.value)
      this.store.dispatch(new UpdateBillingInfo(this.paymentForm.value.billingInfo));
    }
  }

  onSubmitBankAccount() {
    const struncturedPayment: StructuredPayment = this.getStructuredEmittedPaymentInfo();
    console.log('pay-dialog component 507');
    this.selectedPayment.emit(struncturedPayment);

    this.store.dispatch(new TicketPaymentInfo({
      selectedPaymentDetail: struncturedPayment,
      paymentDetail: null,
      paymentTypeConfirm: false
    }));
  }

  drawCardAndAddEvents() {
    const style = {
      base: {
        iconColor: '#666EE8',
        color: '#2e3638',
        lineHeight: '40px',
        fontWeight: 400,
        // fontFamily: "'Proxima Nova', serif",
        fontSize: '15px',
        '::placeholder': {
          color: '#6d787e',
        },
      },
    };
    this.cardNumberElem = this.elements.create('cardNumber', {
      style: style,
      placeholder: 'XXXX XXXX XXXX XXXX',
    });
    
    this.cardExpiryElem = this.elements.create('cardExpiry', {
      style: style,
      placeholder: 'MM/YY',
    });
    
    this.cardCvvElem = this.elements.create('cardCvc', {
      style: style,
      placeholder: 'CVV',
    });
    this.mountElementsToCard();

    // add events
    this.cardNumberElem.addEventListener('change', this.cardHandler);
    this.cardCvvElem.addEventListener('change', this.cardHandler);
    this.cardExpiryElem.addEventListener('change', this.cardHandler);
  }

  mountElementsToCard(): void {
    this.cardNumberElem.mount('#card-number-element');
    this.cardExpiryElem.mount('#card-expiry-element');
    this.cardCvvElem.mount('#card-cvc-element');
  }

  removeCardAndEvents() {
    if (this.cardNumberElem) {
      this.cardNumberElem.removeEventListener('change', this.cardHandler);
      this.cardNumberElem.unmount();
    }

    if (this.cardExpiryElem) {
      this.cardExpiryElem.removeEventListener('change', this.cardHandler);
      this.cardExpiryElem.unmount();
    }

    if (this.cardCvvElem) {
      this.cardCvvElem.removeEventListener('change', this.cardHandler);
      this.cardCvvElem.unmount();
    }
  }

  openModal(id): void {
    this.modalService.open(id)
  }

  paymentTypeChange(event): void {
    const value = event.target.value || '';
    if (value === 'bankAccount') {
      this.selectPayment('bankAccount', 0);

      return;
    }

    this.autoFillChoice();
  }

  removeCreditCard(index: number) {
    const lastFourDigits: string = this.cardListToDisplay[index].lastFourDigits;
    
    this.paymentsService.removeCreditCard(lastFourDigits).subscribe(res => {
      this.cardListToDisplay.splice(index, 1);
      this.creditCardList.splice(index, 1);

      // assign
      const selectedNewIndex = this.creditCardList.length < 1 ? 0 : this.creditCardList.length - 1;
      this.paymentForm.patchValue({
        selectedPaymentType: this.creditCardList.length < 1 ? 'newCard' : 'creditCard',
        selectedPaymentIndex: selectedNewIndex,
        selectedPaymentDetail: this.cardListToDisplay[selectedNewIndex]
      });
     
      if (this.creditCardList.length < 1) {
        setTimeout(() => {
          if (!this.cardNumberElem) {
            this.drawCardAndAddEvents();
          } else {
            this.mountElementsToCard();
          }
        }, 10);
      }
      
      this.store.dispatch(new PaymentsActions.RemoveCreditCard(index));
    });
  }
}
