import { createReducer } from "@ngrx/store";
import { ProfileLink } from "src/app/_interface/user-profile.types";
import { NavigationActions } from "./navigation.actions";

export interface NavigationState {
    links: ProfileLink[];
    loading: boolean;
    lastValidLinks: ProfileLink[]; // for fallback while loading new links
}

export const initialState: NavigationState = {
    links: [],
    loading: false,
    lastValidLinks: []
  };
  
export function NavigationReducer (
    state = initialState,
    action: NavigationActions){
    switch (action.type) {
        case 'LOAD_NAVIGATION_LINKS':
            return {
                ...state,
                loading: true
            };
        case 'LOAD_NAVIGATION_LINKS_SUCCESS':
            return {
                ...state,
                links: action.payload.links,
                loading: false,
                lastValidLinks: action.payload.links
            };
        default:
            return state;
    }
}