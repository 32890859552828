import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, take, takeUntil } from 'rxjs';
import { TaskGroup } from 'src/app/_interface/dl-milestones.typings';
import { Quest } from 'src/app/_interface/quest.types';
import { AppState } from 'src/app/_store/app.reducers';

interface TaskGroupExtended extends TaskGroup {
  completedTaskCount?: number;
}

@Component({
  selector: 'app-milestone-tracker',
  templateUrl: './milestone-tracker.component.html',
  styleUrls: ['./milestone-tracker.component.scss']
})
export class MilestoneTrackerComponent implements OnInit, OnDestroy {
  quest: Quest;

  milestones: TaskGroupExtended[] = [];

  destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.store.select('quest').pipe(take(3), takeUntil(this.destroyed$)).subscribe((quest) => {
      this.quest = quest.selectedQuestDetail;
    });

    this.store.select('dlMilestones').pipe(takeUntil(this.destroyed$)).subscribe((milestones) => {
      let allMilestones: TaskGroupExtended[] = milestones.milestones.filter((milestone) => milestone.groupStatus !== 'HIDDEN' && milestone.viewType === 'task');
      allMilestones.map((milestone) => {
        milestone.completedTaskCount = milestone.questTasks.filter((task) => task.isTaskCompleted).length;
      });
      this.milestones = [...allMilestones];
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
