import { Action } from '@ngrx/store';
import { Steps, ChangeDisplay, FormValue, BillingInfo } from './donate.reducer';
import { Quest } from 'src/app/_interface/quest.types';
import { FundraisingLinkType } from 'src/app/_interface/fundraise-link.types';

export const DONATE_SET_SUCCESS_INFO = 'DONATE_SET_SUCCESS_INFO';
export const SET_BRUT_TOTAL = 'SET_BRUT_TOTAL';
export const SET_BRAND_ID = 'SET_BRAND_ID';
export const SET_BRAND_NAME = 'SET_BRAND_NAME';
export const SET_SECONDARY_BRAND_ID = 'SET_SECONDARY_BRAND_ID';
export const SET_AMOUNT = 'UPDATE_AMOUNT';
export const SET_MESSAGE = 'UPDATE_MESSAGE';
export const SET_TIP_PERCENT = 'SET_TIP_PERCENT';
export const SET_TIP_NUMBER = 'SET_TIP_NUMBER';
export const SET_RECURRENT_CHECK = 'SET_RECURRENT_CHECK';
export const SET_CHANGE_DISPLAY = 'SET_CHANGE_DISPLAY';
export const SET_ANON_CHECK = 'SET_ANON_CHECK';
export const SET_MAIL_CHECK = 'SET_MAIL_CHECK';
export const SET_NAVIGATION_STEP = 'SET_NAVIGATION_STEP';
export const SET_OFFLINE_FLAG = 'SET_OFFLINE_FLAG';
export const SET_ADMIN_FLAG = 'SET_ADMIN_FLAG';
export const SET_TIPPING_FLAG = 'SET_TIPPING_FLAG';
export const SET_MONTHLY_FLAG = 'SET_MONTHLY_FLAG';
export const SET_FORM_VALUES = 'SET_FORM_VALUES';
export const SET_ABSORB_FEES = 'SET_ABSORB_FEES';
export const SET_STRIPE_FEE = 'SET_STRIPE_FEE';
export const SET_PLATFORM_FEE = 'SET_PLATFORM_FEE';
export const SET_PAY_LATER = 'SET_PAY_LATER';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const DONATE_QUEST_DETAIL = 'DONATE_QUEST_DETAIL';
export const DONATE_FOR_TEAMID = 'DONATE_FOR_TEAMID';
export const DONATE_FUNDRAISING_LINK = 'DONATE_FUNDRAISING_LINK';
export const IS_CUSTOM_TIP = 'IS_CUSTOM_TIP';
export const UPDATE_BILLING_INFO = 'UPDATE_BILLING_INFO';
export const UPDATE_PAYMENT_DETAILS = 'UPDATE_PAYMENT_DETAILS';
export const UPDATE_SINGUP_VALUE = 'UPDATE_SINGUP_VALUE';

export interface ToggleCategoryPayload {
  index: number;
  value: boolean;
}

export class SetBrutTotal implements Action {
  readonly type = SET_BRUT_TOTAL;
  constructor(public payload: number | string | null) {}
}
export class SetBrandId implements Action {
  readonly type = SET_BRAND_ID;
  constructor(public payload: number) {
    console.log('DonateActions::SetBrandId', payload);
  }
}

export class SetBrandName implements Action {
  readonly type = SET_BRAND_NAME;
  constructor(public payload: string) {
    console.log('DonateActions::SetBrandName', payload);
  }
}

export class SetSecondaryBrandId implements Action {
  readonly type = SET_SECONDARY_BRAND_ID;
  constructor(public payload: number) {
    console.log('DonateActions::SetSecondaryBrandId', payload);
  }
}
export class SetAmount implements Action {
  readonly type = SET_AMOUNT;
  constructor(public payload: number | null) {}
}
export class SetMessage implements Action {
  readonly type = SET_MESSAGE;
  constructor(public payload: string) {
    console.log('DonateActions::SetMessage', payload);
  }
}
export class SetTipPercent implements Action {
  readonly type = SET_TIP_PERCENT;
  constructor(public payload: number) {
    console.log('DonateActions::SetTipPercent', payload);
  }
}
export class SetTipAmount implements Action {
  readonly type = SET_TIP_NUMBER;
  constructor(public payload: number) {
    console.log('DonateActions::SetTipAmount', payload);
  }
}
export class SetOfflineFlag implements Action {
  readonly type = SET_OFFLINE_FLAG;
  constructor(public payload: boolean) {
    console.log('DonateActions::SetOfflineFlag', payload);
  }
}
export class SetAdminFlag implements Action {
  readonly type = SET_ADMIN_FLAG;
  constructor(public payload: boolean) {}
}
export class SetRecurrentCheck implements Action {
  readonly type = SET_RECURRENT_CHECK;
  constructor(public payload: boolean) {}
}
export class SetChangeDisplay implements Action {
  readonly type = SET_CHANGE_DISPLAY;
  constructor(public payload: ChangeDisplay) {}
}
export class SetAnonCheck implements Action {
  readonly type = SET_ANON_CHECK;
  constructor(public payload: boolean) {}
}
export class SetMailCheck implements Action {
  readonly type = SET_MAIL_CHECK;
  constructor(public payload: boolean) {}
}
export class SetNavigationStep implements Action {
  readonly type = SET_NAVIGATION_STEP;
  constructor(public payload: Steps) {}
}
export class DonateSetSuccessInfo implements Action {
  readonly type = DONATE_SET_SUCCESS_INFO;
  constructor(public payload: any) {}
}
export class SetTippingFlag implements Action {
  readonly type = SET_TIPPING_FLAG;
  constructor(public payload: boolean) {}
}
export class SetMonthlyFlag implements Action {
  readonly type = SET_MONTHLY_FLAG;
  constructor(public payload: boolean) {}
}

export class SetAbsorbFees implements Action {
  readonly type = SET_ABSORB_FEES;
  constructor(public payload: boolean) {}
}

export class SetStripeFee implements Action {
  readonly type = SET_STRIPE_FEE;
  constructor(public payload: number) {}
}

export class SetPlatformFee implements Action {
  readonly type = SET_PLATFORM_FEE;
  constructor(public payload: number) {}
}

export class SetPayLater implements Action {
  readonly type = SET_PAY_LATER;
  constructor(public payload: boolean) {}
}

export class SetFormValues implements Action {
  readonly type = SET_FORM_VALUES;
  constructor(public payload: FormValue) {}
}

export class SetLoadingStatus implements Action {
  readonly type = SET_LOADING_STATUS;
  constructor(public payload: boolean) {
    console.log('DonateActions::SetLoadingStatus');
  }
}

export class SetQuestDetail implements Action {
  readonly type = DONATE_QUEST_DETAIL;
  constructor(public payload: Quest) {}
}

export class DonateForTeamId implements Action {
  readonly type = DONATE_FOR_TEAMID;
  constructor(public payload: number) {}
}

export class DonateFundraisingLink implements Action {
  readonly type = DONATE_FUNDRAISING_LINK;
  constructor(public payload: FundraisingLinkType) {}
}

export class IsCustomTip implements Action {
  readonly type = IS_CUSTOM_TIP;
  constructor(public payload: boolean) {}
}

export class UpdateBillingInfo implements Action {
  readonly type = UPDATE_BILLING_INFO;
  constructor(public payload: BillingInfo) {}
}

export class UpdatePaymentDetails implements Action {
  readonly type = UPDATE_PAYMENT_DETAILS;
  constructor(public payload: any) {}
}

export class UpdateSignUpValue implements Action {
  readonly type = UPDATE_SINGUP_VALUE;
  constructor(public payload: boolean) {}
}

export type DonateActions =   SetAmount |
                              SetMessage |
                              SetTipPercent |
                              SetTipAmount |
                              SetRecurrentCheck |
                              SetAnonCheck |
                              SetMailCheck |
                              SetNavigationStep |
                              SetChangeDisplay |
                              DonateSetSuccessInfo |
                              SetBrutTotal |
                              SetBrandId |
                              SetSecondaryBrandId |
                              SetOfflineFlag |
                              SetAdminFlag |
                              SetTippingFlag |
                              SetMonthlyFlag | 
                              SetFormValues | 
                              SetPayLater |
                              SetPlatformFee |
                              SetStripeFee |
                              SetAbsorbFees |
                              SetLoadingStatus |
                              SetQuestDetail |
                              DonateForTeamId |
                              SetBrandName |
                              DonateFundraisingLink |
                              IsCustomTip |
                              UpdateBillingInfo |
                              UpdatePaymentDetails |
                              UpdateSignUpValue;
