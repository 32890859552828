import * as PaymentsActions from './payments.actions';

export interface CreditCard {
  cardType: string;
  expiryMonth: number;
  expiryYear: number;
  lastFourDigits: string;
  save?: boolean;
  token?: string;
}

export interface BankAccount {
  id: string;
  name: string;
  verified: boolean;
  customer: boolean;
  lastFourDigits?: string;
}

export interface State {
  bankAccounts: BankAccount[];
  creditCards: CreditCard[];
  hasBankAccountForPayments: boolean;
  hasBankAccountForPayouts: boolean;
  addBankAccountMessage?: string;
}

const initialState: State = {
  bankAccounts: [],
  creditCards: [],
  hasBankAccountForPayments: false,
  hasBankAccountForPayouts: false,
  addBankAccountMessage: null
};


export function paymentsReducer(state = initialState, action: PaymentsActions.PaymentsActions) {
  switch (action.type) {
    case (PaymentsActions.SET_PAYMENTS_INFO):
      return {
        ...state,
        bankAccounts: [...action.payload.bankAccounts],
        creditCards: [...action.payload.creditCards],
        hasBankAccountForPayments:  action.payload.hasBankAccountForPayments,
        hasBankAccountForPayouts: action.payload.hasBankAccountForPayouts
      };
    case (PaymentsActions.ADD_CREDIT_CARD):
      return {
        ...state,
        creditCards: [
          ...state.creditCards,
          action.payload
        ]
      };
    case (PaymentsActions.REMOVE_CREDIT_CARD):
      const oldCreditCards = [...state.creditCards];
      oldCreditCards.splice(action.payload, 1);
      return {
        ...state,
        creditCards: oldCreditCards
      };
    case (PaymentsActions.ADD_BANK_ACCOUNT):
      return {
        ...state,
        bankAccounts: [
          ...state.bankAccounts,
          action.payload
        ]
      };
    case (PaymentsActions.REMOVE_BANK_ACCOUNT):
      const oldBankAccounts = [...state.bankAccounts];
      oldBankAccounts.splice(action.payload, 1);
      return {
        ...state,
        bankAccounts: oldBankAccounts
      };
    case (PaymentsActions.ADD_BANK_ACCOUNT_MESSAGE):
      return {
        ...state,
        addBankAccountMessage: action.payload
      };
    default:
      return state;
  }
}
