import { Quest } from 'src/app/_interface/quest.types';
import * as DonateActions from './donate.actions';
import { DEFAULT_DIEMLIFE_TIP_PERCENT } from 'src/app/app.constants';
import { FundraisingLinkType } from 'src/app/_interface/fundraise-link.types';

export interface Steps {
  amount: boolean;
  success: boolean;
}

export interface BillingInfo {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  zip: string;
}

export interface ChangeDisplay {
  donorFirstName: string;
  donorLastName: string;
  donorDisplay: boolean;
  backerFirstName: string;
  backerLastName: string;
  backerDisplay: boolean;
  donatingFor: string;
}

export interface FormValue {
  step1?: {
    value: any;
    valid: boolean;
    submitted?: boolean;
  };
  step3?: {
    value: any;
    valid: boolean;
    submitted?: boolean;
  };
}

export interface State {
  brandId: number;
  selectedBrandName: string;
  secondaryBrandId: number;
  amount: number;
  tipPercent: number;
  tipAmount: number;
  message: string;
  steps: Steps;
  changeDisplay: ChangeDisplay;
  billing: {
    recurrent: boolean,
    anonymous: boolean
  };
  mailing: boolean;
  successInfo: any;
  brutTotal: any;
  offlineFlag: boolean;
  userAdmin: boolean;
  tippingFlag: boolean;
  monthlyFlag: boolean;
  formDetails: FormValue;
  isLoading: boolean;
  quest: Quest;
  creatorAbsorbFees?: number;
  stripeFee?: number;
  platformFee?: number;
  payLater?: boolean;
  teamId: number;
  fundraisingLink: FundraisingLinkType;
  isCustomTip: boolean;
  billingInfo: BillingInfo;
  paymentDetails: any;
  signUpUser: boolean;
  disclaimer: string;
}

export const initialState: State = {
  brandId: null,
  selectedBrandName: null,
  secondaryBrandId: null,
  amount: 0,
  tipPercent: DEFAULT_DIEMLIFE_TIP_PERCENT,
  tipAmount: 0,
  message: '',
  steps: {
    amount: true,
    success: false
  },
  changeDisplay: {
    donorFirstName: null,
    donorLastName: null,
    donorDisplay: false,
    backerFirstName: null,
    backerLastName: null,
    backerDisplay: false,
    donatingFor: null
  },
  billing: {
    recurrent: false,
    anonymous: false,
  },
  mailing: false,
  successInfo: {},
  brutTotal: 0,
  offlineFlag: false,
  userAdmin: false,
  tippingFlag: false,
  monthlyFlag: false,
  formDetails: {
    step1: {
      value: null,
      valid: false,
      submitted: false
    },
    step3: {
      value: null,
      valid: false,
      submitted: false
    }
  },
  creatorAbsorbFees: null,
  stripeFee: 0,
  platformFee: 0,
  payLater: false,
  isLoading: false,
  quest: null,
  teamId: null,
  fundraisingLink: null,
  isCustomTip: false,
  billingInfo: {
    firstName: null,
    lastName: null,
    email: null,
    country: null,
    zip: null
  },
  paymentDetails: null,
  signUpUser: false,
  disclaimer: null
};

export function QuestDonateReducer(state = initialState, action: DonateActions.QuestDonateActions) {
  switch (action.type) {
    case (DonateActions.SET_AMOUNT):
      return {
        ...state,
        amount: action.payload
      };
    case (DonateActions.SET_MESSAGE):
      return {
        ...state,
        message: action.payload
      };
    case (DonateActions.SET_TIP_PERCENT):
      return {
        ...state,
        tipPercent: action.payload
      }
    case DonateActions.SET_TIP_NUMBER:
      return {
        ...state,
        tipAmount: action.payload
      }
    case (DonateActions.SET_RECURRENT_CHECK):
      const billing = {...state.billing};
      billing.recurrent = action.payload;
      return {
        ...state,
        billing: billing
      };
    case (DonateActions.SET_ANON_CHECK):
      const oldBilling = {...state.billing};
      oldBilling.anonymous = action.payload;
      return {
        ...state,
        billing: oldBilling
      };
    case (DonateActions.SET_MAIL_CHECK):
      return {
        ...state,
        mailing: action.payload
      };
    case (DonateActions.SET_NAVIGATION_STEP):
      return {
        ...state,
        steps: action.payload
      };
    case (DonateActions.SET_CHANGE_DISPLAY):
      return {
        ...state,
        changeDisplay: action.payload
      };
    case (DonateActions.DONATE_SET_SUCCESS_INFO):
      return {
        ...state,
        successInfo: action.payload
      };
    case (DonateActions.SET_BRUT_TOTAL):
      return {
        ...state,
        brutTotal: action.payload
      };
    case (DonateActions.SET_BRAND_ID):
      return {
        ...state,
        brandId: action.payload
      };
    case (DonateActions.SET_BRAND_NAME):
      return {
        ...state,
        selectedBrandName: action.payload
      }
    case (DonateActions.SET_SECONDARY_BRAND_ID):
      return {
        ...state,
        secondaryBrandId: action.payload
      };
    case (DonateActions.SET_OFFLINE_FLAG):
      return {
        ...state,
        offlineFlag: action.payload
      };
    case (DonateActions.SET_ADMIN_FLAG):
      return {
        ...state,
        userAdmin: action.payload
      };
    case (DonateActions.SET_TIPPING_FLAG):
      return {
        ...state,
        tippingFlag: action.payload
      }
    case (DonateActions.SET_MONTHLY_FLAG):
      return {
        ...state,
        monthlyFlag: action.payload
      }
    case (DonateActions.SET_FORM_VALUES):
      return {
        ...state,
        formDetails: action.payload        
      }
    case (DonateActions.SET_ABSORB_FEES):
      return {
        ...state,
        creatorAbsorbFees: action.payload    
      }
    case (DonateActions.SET_STRIPE_FEE):
      return {
        ...state,
        stripeFee: action.payload    
      }
    case (DonateActions.SET_PLATFORM_FEE):
      return {
        ...state,
        platformFee: action.payload    
      }
    case (DonateActions.SET_PAY_LATER):
      return {
        ...state,
        payLater: action.payload    
      }
    case (DonateActions.SET_LOADING_STATUS):
      return {
        ...state,
        isLoading: action.payload
      }
    case (DonateActions.DONATE_QUEST_DETAIL):
      return {
        ...state,
        quest: action.payload
      }
    case DonateActions.DONATE_FUNDRAISING_LINK:
      return {
        ...state,
        fundraisingLink: action.payload
      }
    case (DonateActions.DONATE_FOR_TEAMID):
      return {
        ...state,
        teamId: action.payload
      }
    case DonateActions.IS_CUSTOM_TIP:
      return {
        ...state,
        isCustomTip: action.payload
      };
    case DonateActions.UPDATE_BILLING_INFO:
      return {
        ...state,
        billingInfo: action.payload
      }
    case DonateActions.UPDATE_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: action.payload
      }
    case DonateActions.UPDATE_SINGUP_VALUE:
      return {
        ...state,
        signUpUser: action.payload
      }
    case DonateActions.UPDATE_DISCLAIMER_MSG:
      return {
        ...state,
        disclaimer: action.payload
      }  
    default:
      return state;
  }
}

export const questDonateBrandIdChange = (state: State) => state.brandId