import { ProfileLink } from 'src/app/_interface/user-profile.types';
import { QuestUserInfo } from '../../../../_interface/quest.types';
import * as ProfileActions from './profile-visit.actions';

export interface State extends QuestUserInfo {
  profileLinks?: ProfileLink[];
}

export const initialState: State = {
  active: false,
  country: null,
  coverPictureURL: null,
  createdOn: null,
  email: null,
  emailValidated: false,
  firstName: null,
  id: null,
  identifier: null,
  isUserBrand: null,
  lastLogin: null,
  lastName: null,
  missionStatement: null,
  name: null,
  profilePictureURL: null,
  receiveEmail: null,
  stripeEntityId: null,
  updatedOn: null,
  userName: null,
  userNonProfit: false,
  zip: null,
  absorbFees: false,
  plan: null,
  centerX: null,
  centerY: null,
  zoomValue: null,
  accountTier: null,
  userAbsorbFees: null,
  themeColor: null,
  accentColor: null,
  profileLinks: []
};

export function ProfileVisitReducer(
  state = initialState,
  action: ProfileActions.ProfileActions
) {
  switch (action.type) {
    case (ProfileActions.SET_VISIT_USER_INFO):
      return {
        ...state,
        ...action.payload
      };
    case ProfileActions.RESET_VISIT_USER_INFO:
      return {
        ...state,
        ...initialState
      };
    case ProfileActions.SET_VISIT_PROFILE_LINKS:
      
      return {
        ...state,
        profileLinks: action.payload
      };
    case ProfileActions.UPDATE_VISIT_PROFILE_LINKS:
      const updatedLinks = state.profileLinks.map(link =>
        link.id === action.payload.id ? { ...link, ...action.payload } : link
      );
      return {
        ...state,
        profileLinks: [...updatedLinks]
      };
    default:
      return state;
  }
}
