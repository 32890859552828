import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, timer } from 'rxjs';
import { Router } from '@angular/router';
import * as fromApp from './../../../_store/app.reducers';
import tools from './../../../_tools';
import * as config from './../../../app.config';
import * as AuthActions from './../../main/auth/store/auth.actions';
import * as fromAuth from './store/auth.reducer';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private autoRefreshTokenEnabled: Boolean = true;
  timeBeforeTokenExpired: number = config.timeBeforeTokenExpired;
  authState: Observable<fromAuth.State>;
  stateSubscriber: Subscription;
  refreshTokenTimer: Observable<number> = null;
  refreshTokenTimerSubscriber: Subscription;
  logoutTimer: Observable<number> = null;
  logoutTimerSubscriber: Subscription;
  tokenUpdateTime: number =  null;
  public redirectUrl: string;

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router
  ) {
    this.authState = this.store.select('auth');
    this.stateSubscriber = this.authState.subscribe(
      tools.debounce((authState: fromAuth.State) => {
        this.tryStartAutoRefreshTokenTimer(authState);
      }, 0)
    );
  }

  refreshToken() {
    this.store.dispatch(new AuthActions.TryRefreshToken());
  }

  logout() {
    this.store.dispatch(new AuthActions.TryLogout());
  }

  forceLogout() {
    this.store.dispatch(new AuthActions.Logout());
  }

  enableAutoRefreshToken() {
    this.autoRefreshTokenEnabled = true;
  }

  disableAutoRefreshToken() {
    this.autoRefreshTokenEnabled = false;
  }

  tryStartAutoRefreshTokenTimer(authState: fromAuth.State) {
    this.stopAutoRefreshTokenTimer();
    if (authState.authenticated) {
      this.tokenUpdateTime = authState.tokenUpdateTime;
      this.startAutoRefreshTokenTimer(authState.expires - this.timeBeforeTokenExpired, authState.expires);
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      }
    } else {
      this.stopAutoRefreshTokenTimer();
    }
  }

  startAutoRefreshTokenTimer(time: number, expires: number) {
    this.refreshTokenTimer = timer(time);
    this.refreshTokenTimerSubscriber = this.refreshTokenTimer.subscribe(() => {
      if (this.autoRefreshTokenEnabled) {
        this.refreshToken();
      } else {
        this.startLogoutTimer(expires);
      }
    });
  }

  stopAutoRefreshTokenTimer() {
    if (this.refreshTokenTimerSubscriber) { this.refreshTokenTimerSubscriber.unsubscribe(); }
    this.refreshTokenTimer = null;
  }

  checkTokenValid(authState: fromAuth.State) {
    const now = new Date();
    this.tokenUpdateTime = authState.tokenUpdateTime;
    if (authState.tokenUpdateTime - now.getTime() + authState.expires <= 0) {
      this.forceLogout();
      return false;
    } else {
      this.tryStartAutoRefreshTokenTimer(authState);
      this.refreshToken();
    }
    return true;
  }

  startLogoutTimer(expires: number) {
    const now = new Date();
    this.logoutTimer = timer(now.getTime() - this.tokenUpdateTime);
    this.logoutTimerSubscriber = this.logoutTimer.subscribe(() => {
      this.logoutTimerSubscriber.unsubscribe();
      this.forceLogout();
    });
  }
}
