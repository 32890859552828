import { Action } from '@ngrx/store';
import { ProfileLink } from 'src/app/_interface/user-profile.types';
import * as fromProfile from './profile-visit.reducer';

export const SET_VISIT_USER_INFO = 'SET_VISIT_USER_INFO';
export const RESET_VISIT_USER_INFO = 'RESET_VISIT_USER_INFO';
export const SET_PROFILE_LINKS = 'SET_PROFILE_LINKS';

export class SetVisitUserInfo implements Action {
  readonly type = SET_VISIT_USER_INFO;
  constructor(public payload: fromProfile.State) {
    console.log('SetVisitUserInfo::', payload);
  }
}

export class ResetVisitUserInfo implements Action {
  readonly type = RESET_VISIT_USER_INFO;
  constructor() {
    console.log('ResetVisitUserInfo::');
  }
}

export class SetVisitProfileLinks implements Action {
  readonly type = SET_PROFILE_LINKS;

  constructor(public payload: ProfileLink[]) {
    console.log('SetVisitProfileLinks::', payload);
  }
}

export type ProfileActions =  
  SetVisitUserInfo |
  ResetVisitUserInfo |
  SetVisitProfileLinks;
